import React from 'react';
import {
  EditTeamSchema,
  ProjectPermissions,
  TeamMembership,
  UserWithPermissionsSchema,
  UserSchema,
  RoleName,
  ProjectDetailsSchema,
  ProjectUsersWithPermissions,
  TeamWithProjectIdsSchema,
  ProjectSchema,
  HasIdAndName, EditProjectSchema,
} from './types';
import { EditUserSchema, TeamWithProjectsSchema } from '@/Accounts/types';
import _ from 'lodash';
import askForConfirmation, { ConfirmationDialog } from '../shared/components/ConfirmationDialog';
import { keysAsNumbers } from '@/shared/utils/objectKeys';

export type ActionWithLabel = { label: string | string[]; action: () => Promise<any>; }; // eslint-disable-line @typescript-eslint/no-explicit-any

export class AccountsUtils {
  /**
   * Perform a comparison (like localCompare) against two objects which either are of type UserSchema or
   * have a name property. In the case of users, the comparison is done using last name first. Case is
   * ignored
   * @param a user or object with name property
   * @param b user or object with name property
   * @returns -1, 0, 1
   */
  static compareNames(a: Partial<UserSchema> | HasIdAndName, b: Partial<UserSchema> | HasIdAndName) {
    const getVal = (val: Partial<UserSchema> | HasIdAndName) => {
      if ('name' in val) {
        return (val.name ?? '').toLowerCase();
      } else {
        return `${val.last_name}, ${val.first_name}`.toLowerCase();
      }
    };
    return getVal(a).localeCompare(getVal(b));
  }

  static isReadonlyVaultRole(role: string) {
    return ['read-export', 'readonly', 'read-download'].includes(role);
  }

  static formatUserName(user: UserSchema, permissions?: TeamMembership) {
    if (!user) {
      return '';
    }
    const permissionsText = permissions
      ? ': ' + AccountsUtils.convertPermissionsToString(permissions)
      : '';
    const disabledText = user.disabled ? ' (DISABLED)' : '';

    return `${user.first_name} ${user.last_name}${disabledText}: ${user.email} ${permissionsText}`;
  }

  /**
   * Given an array of label and action (functions that return promises) pairs, perform all the actions.
   * If 'askToConfirm' is passed, then the labels will be first consolidated into a list, and the user will
   * be asked to confirm the actions first.
   * @param changes
   * @param askToConfirm
   * @returns
   */
  static performChanges(
    changes: Array<ActionWithLabel>,
    askToConfirm: boolean,
    // optional function to display confirmation - if not passed, the default is used
    customConfirmation?: (props: Partial<React.ComponentProps<typeof ConfirmationDialog>>) => Promise<boolean>) {
    return new Promise<{
      cancelled: boolean,
      failures?: boolean,
      completedActions?: Array<ActionWithLabel>,
      results?: Array<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    }>(resolve => {
      const completedActions: Array<ActionWithLabel> = [];
      const actionResults: Array<any> = []; // eslint-disable-line @typescript-eslint/no-explicit-any

      // actually perform the changes, once any optional confirmation has been given
      const performChanges = async () => {
        try {
          for (let i = 0; i < changes.length; i++) {
            actionResults.push(await changes[i].action());
            completedActions.push(changes[i]);
          }
        } finally {
          resolve({
            cancelled: false,
            failures: completedActions.length !== changes.length,
            completedActions,
            results: actionResults,
          });
        }
      };

      if (askToConfirm) {
        const allChanges: string[] = [];
        changes.forEach((item) => {
          if (typeof item.label === 'string') {
            allChanges.push(item.label);
          } else {
            allChanges.push(...item.label);
          }
        });

        const confirmingSaveContents = (
          <>
            <div>Select &quot;Ok&quot; to perform the following changes:</div>
            <ul>
              {allChanges.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </>
        );

        (customConfirmation ?? askForConfirmation)({ message: confirmingSaveContents })
          .then((result) => {
            if (result) {
              performChanges();
            } else {
              resolve({ cancelled: true });
            }
          }).catch(() => {
            resolve({ cancelled: true });
          });
      } else {
        performChanges();
      }
    });
  }

  static convertPermissionsToString(permissions: ProjectPermissions | TeamMembership) {
    const result = [];

    if (permissions.can_edit_data) {
      result.push('edit');
    }

    if (permissions.can_manage_project) {
      result.push('manage');
    }

    if (!result.length) {
      return 'read-only';
    } else if (result.length === 1) {
      return result[0];
    } else {
      return result.join(' and ');
    }
  }

  static convertToEditUser(user: UserWithPermissionsSchema,
    teamsMap: { [id: number]: TeamWithProjectIdsSchema; },
    projectsMap: { [id: number]: ProjectSchema; },
    vaultsMap: { [id: number]: unknown; }): EditUserSchema {
    // project_permissions should only contain project_permissions for this specific user, not team-inherited ones
    // (those are computed dynamically)
    const result = {
      ...user,
      vault_permissions: _.keyBy(user.vault_permissions ?? [], 'vault_id'),
      project_permissions: _.keyBy(
        (user.project_permissions ?? []).filter(perm => !perm.compiled_from_teams),
        'project_id',
      ),
      team_memberships: _.keyBy(user.team_memberships ?? [], 'team_id'),
    };
    // filter out missing data to prevent crashes (which shouldn't be happening, but does)
    keysAsNumbers(result.team_memberships).forEach(teamId => {
      if (!teamsMap[teamId]) {
        console.log(`ignoring unknown team ${teamId}`);
        delete result.team_memberships[teamId];
      }
    });
    keysAsNumbers(result.project_permissions).forEach(projectId => {
      if (!projectsMap[projectId]) {
        console.log(`ignoring unknown project ${projectId}`);
        delete result.project_permissions[projectId];
      }
    });
    keysAsNumbers(result.vault_permissions).forEach(vaultId => {
      if (!vaultsMap[vaultId]) {
        console.log(`ignoring unknown vault ${vaultId}`);
        delete result.vault_permissions[vaultId];
      }
    });
    return result;
  }

  static convertToEditTeam(team: TeamWithProjectsSchema,
    usersMap: { [id: number]: UserWithPermissionsSchema; },
    projectsMap: { [id: number]: ProjectSchema; }): EditTeamSchema {
    const result = {
      ...team,
      user_membership: _.keyBy(team.team_memberships ?? [], 'user_id'),
      project_ids: team.projects?.map((item) => item.id) ?? [],
      add_users_to_vaults: {},
    };

    // filter out missing data to prevent crashes (which shouldn't be happening, but does)
    keysAsNumbers(result.user_membership).forEach(userId => {
      if (!usersMap[userId]) {
        console.log(`ignoring unknown user ${userId}`);
        delete result.user_membership[userId];
      }
    });
    result.project_ids = result.project_ids.filter(projectId => {
      if (!projectsMap[projectId]) {
        console.log(`ignoring unknown project ${projectId}`);
        return false;
      }
      return true;
    });
    delete result.projects;
    delete result.team_memberships;

    return result;
  }

  static convertToEditProject(projectUsers: ProjectUsersWithPermissions,
    projectDetails: ProjectDetailsSchema,
    usersMap: { [id: number]: UserWithPermissionsSchema; },
    teamsMap: { [id: number]: TeamWithProjectIdsSchema; },
  ): EditProjectSchema {
    const result: EditProjectSchema = {
      ...projectDetails,
      project_permissions: {},
      add_users_to_vault: {},
      team_ids: [],
      user_order: [],
    };

    const sortedUsers = projectUsers.sort(AccountsUtils.compareNames);

    sortedUsers.forEach((item) => {
      const projectPermissions = item.project_permissions[0];
      if (!usersMap[item.id]) {
        console.log(`ignoring unknown user ${item.id}`);
        return;
      }
      result.project_permissions[item.id] = {
        ...projectPermissions,
        // make sure these are booleans
        can_edit_data: !!projectPermissions.can_edit_data,
        can_manage_project: !!projectPermissions.can_manage_project,
      };
      result.user_order.push(item.id);
    });

    Object.values(teamsMap).forEach((team) => {
      if (team.project_ids.includes(result.id)) {
        result.team_ids.push(team.id);
      }
    });
    return result;
  }
}
