import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function OpenLinkIcon(props = { id: 'openlink-icon', 'disabled': false }) {
  const opacity = props.disabled ? '0.4' : '1'
  const color = props.color || '#000000'
  return (
    <SvgIcon {...props} viewBox="0 0 5.2916665 5.2916666">
      <g opacity={opacity}>
        <path fill={color} d="M 0.26367188,0.59375 A 0.20001999,0.20001999 0 0 0 0.06445312,0.79296875 V 5.0273437 A 0.20001999,0.20001999 0 0 0 0.26367188,5.2265625 H 4.4980469 A 0.20001999,0.20001999 0 0 0 4.6972656,5.0273437 V 2.6464844 A 0.19999999,0.19999999 0 0 0 4.4980469,2.4453125 0.19999999,0.19999999 0 0 0 4.2988281,2.6464844 V 4.8261719 H 0.46484375 V 0.99414063 H 2.6464844 A 0.19999999,0.19999999 0 0 0 2.8457031,0.79296875 0.19999999,0.19999999 0 0 0 2.6464844,0.59375 Z"/>
        <path fill={color} d="M 3.1757813,0.06445312 A 0.19999999,0.19999999 0 0 0 2.9746094,0.26367188 0.19999999,0.19999999 0 0 0 3.1757813,0.46484375 H 4.8261719 V 2.1171875 A 0.19999999,0.19999999 0 0 0 5.0273437,2.3164063 0.19999999,0.19999999 0 0 0 5.2265625,2.1171875 V 0.06445312 Z"/>
        <path fill={color} d="M 5.0273437,0.06445312 A 0.19999999,0.19999999 0 0 0 4.8847656,0.12304687 L 1.4453125,3.5625 a 0.19999999,0.19999999 0 0 0 0,0.2832031 0.19999999,0.19999999 0 0 0 0.2832031,0 L 5.1679687,0.40625 a 0.19999999,0.19999999 0 0 0 0,-0.28320313 0.19999999,0.19999999 0 0 0 -0.140625,-0.05859375 z"/>
      </g>
    </SvgIcon>
  )
}
