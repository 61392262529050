import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable } from 'mobx';

/**
 * A simple store for persisting observable data in the local store.
 * Anything added to LocalPersistenceStore.data and set via setData() will be automatically persisted.
 *
 * Access this through getRootStore().localPersistenceStore.
 */
export class LocalPersistenceStore {
  static readonly KEY = 'LocalPersistenceStore.data';

  readonly data = {
    structureExpanded: false,
  };

  constructor(public readonly root: RootStore) {
  }

  init() {
    const persistedData = JSON.parse(localStorage.getItem(LocalPersistenceStore.KEY) ?? '{}');
    Object.keys(persistedData).forEach(key => {
      this.data[key] = persistedData[key];
    });
    makeAutoObservable(this);
  }

  setData(value: Partial<typeof this.data>) {
    Object.assign(this.data, value);
    localStorage.setItem(LocalPersistenceStore.KEY, JSON.stringify(this.data));
  }
}
