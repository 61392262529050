import {
  CustomAminoAcidsSchema,
  EditCustomAminoAcidsSchema,
} from '@/Accounts/CustomAminoAcids/types';
import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { accountsService } from '../../accountsServices';
import { CustomAminoAcidsUtils } from '../CustomAminoAcidsUtils';

export class EditCustomAminoAcidStore {
  inited = false;
  disposers: Array<() => void> = [];
  isOpen = false;
  value: EditCustomAminoAcidsSchema = null; // the value currently being edited

  constructor(public readonly root: RootStore) {
    this.root = root;
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get codes() {
    const codes = this.value.codes ?? '';

    // Do some clean up on the codes -- add some brackets
    const codeStringList = codes.split(',');
    const newCodeList: string[] = [];

    for (let code of codeStringList) {
      code = code.trim();
      if (code.length) {
        if (!code.startsWith('[')) {
          code = '[' + code;
        }
        if (!code.endsWith(']')) {
          code = code + ']';
        }
        newCodeList.push(code);
      }
    }

    return newCodeList.join(',');
  }

  get existingResidues() {
    return this.root.accountsStore.customAminoAcidsStore.customAminoAcids;
  }

  get codesError() {
    const { codes, existingResidues, value } = this;

    if (!codes) {
      return 'One or more codes are required';
    }

    // Build existing code List
    const existingCodes = [];
    for (const existingCustomNucleotide of existingResidues) {
      if (existingCustomNucleotide.id != value.id) {
        const existingCodeList = existingCustomNucleotide.codes.split(',');
        for (const aCode of existingCodeList) {
          existingCodes.push(aCode.toUpperCase());
        }
      }
    }

    const codeStringList = codes.split(',');
    const conflictCodesList: string[] = [];
    const returnErrors: string[] = [];

    for (const code of codeStringList) {
      if (code.substring(1, code.length - 1).indexOf('[') != -1) {
        returnErrors.push('The code ' + code + ' cannot contain a nested open bracket');
      } else if (code.substring(1, code.length - 1).indexOf(']') != -1) {
        returnErrors.push('The code ' + code + ' cannot contain a nested close bracket');
      }

      if (existingCodes.includes(code.toUpperCase())) {
        conflictCodesList.push(code);
      }
    }

    if (conflictCodesList.length > 0) {
      returnErrors.push('The following codes are already in use: ' + conflictCodesList.join(','));
    }

    return returnErrors.length ? returnErrors.join('; ') : null;
  }

  get nameError() {
    const { value: { name, id }, existingResidues } = this;
    if (!name) {
      return 'Name is required';
    } else {
      // Build existing name List
      const existingNames = [];
      for (const existingCustomNucleotide of existingResidues) {
        if (existingCustomNucleotide.id != id) {
          const existingName = existingCustomNucleotide.name;
          existingNames.push(existingName.toUpperCase());
        }
      }

      if (existingNames.includes(name.toUpperCase())) {
        return 'This name is already in use';
      }
    }

    return null;
  }

  init() {
    if (!this.inited) {
      this.inited = true;
    }
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    --this.root.accountsStore.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.root.accountsStore.loading;
    this.root.decrementLoading();
  }

  setStructure(mol_file: string, formula: string, molecular_weight: number) {
    this.value.mol_file = mol_file;
    this.value.formula = formula;
    this.value.molecular_weight = molecular_weight;
  }

  setCodes(codes: string) {
    this.value.codes = codes;
  }

  async handleEditCustomAminoAcid(customAminoAcid: CustomAminoAcidsSchema) {
    this.incrementLoading();
    try {
      const response = await accountsService.getCustomAminoAcid(
        customAminoAcid,
      );
      runInAction(() => {
        this.value = CustomAminoAcidsUtils.convertToEditCustomAminoAcid(response.data);
        this.isOpen = true;
      });
    } finally {
      this.decrementLoading();
    }
  }

  handleNewCustomAminoAcid() {
    this.value = {} as EditCustomAminoAcidsSchema;
    this.isOpen = true;
  }

  handleCancelEdit() {
    this.isOpen = false;
  }

  async handleSubmit() {
    const { value } = this;
    this.setCodes(this.codes);

    if (!value.id) {
      await accountsService.createCustomAminoAcid(value);
    } else {
      await accountsService.updateCustomAminoAcid(value);
    }

    this.root.accountsStore.reloadData({
      reloadCustomAminoAcids: true,
    });
    this.handleCancelEdit();
  }
}
