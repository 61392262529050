import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function MinusIcon(props = { id: 'minus-icon', 'disabled': false }) {
  const opacity = props.disabled ? '0.4' : '1'
  return (
    <SvgIcon {...props} viewBox="-2 -2 22 22">
      <g opacity={opacity} transform="translate(-91.191333,-39.187272)">
        <rect fill="#5B6067" width="14.552" height="2" x="92.915337" y="47.187271"/>
      </g>
    </SvgIcon>
  )
}
