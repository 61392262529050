/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import _ from 'lodash';
import { diff } from 'deep-object-diff';
import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '@/stores/rootStore';
import {
  EditProjectSchema,
  ProjectSchema,
  RoleName,
  TeamSchema,
  UserSchema,
  VaultUserCreate,
  WithLabel,
} from '@/Accounts/types';
import { accountsService } from '@/Accounts/accountsServices';
import { AccountsUtils } from '@/Accounts/accountsUtils';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { ModalUtils } from '@/shared/utils/modalUtils';

export class EditProjectStore {
  inited = false;
  loading = 0;
  isOpen = false;
  value: EditProjectSchema = null; // the value currently being edited
  valueBeforeEdits: EditProjectSchema = null; // a copy of the value as loaded
  formState: {
    data: {
      step: number;
      filterByVault?: string;
    };
    errors: { [key: string]: string } | null;
  } = { data: { step: 0, filterByVault: '' }, errors: {} };

  disposers: Array<() => void> = [];

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!this.inited) {
      this.inited = true;
    }
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  get vaultsMap() {
    return this.root.accountsStore.vaultsAndProjectsStore.vaultsMap;
  }

  get usersMap() {
    return this.root.accountsStore.usersStore.usersMap;
  }

  get teamsMap() {
    return this.root.accountsStore.teamsStore.teamsMap;
  }

  get teamDetailsMap() {
    return this.root.accountsStore.teamsStore.teamDetailsMap;
  }

  get availableUsersToAdd(): Array<WithLabel<UserSchema>> {
    const { userToRoleNameMap } = this;
    const { usersMap } = this.root.accountsStore.usersStore;

    const isDisabled = (user: UserSchema) => {
      // disabled if the current user isn't a vault admin and this user isn't in the vault
      return !this.currentUserIsVaultAdmin && user && !userToRoleNameMap[user.id];
    };

    return Object.values(usersMap)
      .filter(
        (user) =>
          !this.value?.project_permissions[user.id] ||
          this.value?.project_permissions[user.id].compiled_from_teams,
      )
      .sort(AccountsUtils.compareNames)
      .map((user) => ({
        ...user,
        label: AccountsUtils.formatUserName(user),
        disabled: isDisabled(user),
        disabledMessage: 'Vault Admin must first add this user to the Vault',
      }));
  }

  /**
   * for each user individually in the project, return a map to boolean flags indicating if
   * they are read-only via their vault role..
   */
  get userReadOnlyVaultMap() {
    const result: { [userId: number]: boolean } = {};
    const { userRoleMap } = this.root.accountsStore.vaultsAndProjectsStore;

    keysAsNumbers(this.value?.project_permissions ?? {})
      .forEach(userId => {
        const addToVault = this.value.add_users_to_vault[userId];
        if (addToVault) {
          if (!addToVault.role_name) {
            // when the user needs to be added to the vault but has no role set
            result[userId] = true;
          } else {
            if (AccountsUtils.isReadonlyVaultRole(addToVault.role_name)) {
              result[userId] = true;
            }
          }
        } else {
          if (AccountsUtils.isReadonlyVaultRole(userRoleMap[userId])) {
            result[userId] = true;
          }
        }
      });
    return result;
  }

  get prohibitedNames() {
    // get the list of existing team names
    const { projectsForSelectedVault } =
      this.root.accountsStore.vaultsAndProjectsStore;
    return projectsForSelectedVault
      .map((project) => project.name)
      .filter((name) => name !== this.valueBeforeEdits?.name); // if editing an existing team, remove the current name from the prohibited list
  }

  get canModifyTeams() {
    // not sure if this will ever be not true going forward - at the moment this was written, we
    // don't allow team management for non-admin project managers
    return true;
  }

  get currentUserIsVaultAdmin() {
    // alias
    return this.root.accountsStore.vaultsAndProjectsStore.currentUserIsVaultAdmin;
  }

  get userToRoleNameMap() {
    // determine a map of users to existing role names within this vault
    const {
      usersMap,
      teamsStore: { teamDetailsMap },
      vaultsAndProjectsStore: { usersInSelectedVault },
    } = this.root.accountsStore;

    const userToRoleNameMap: { [userId: number]: string } = {};

    usersInSelectedVault.forEach((user) => {
      const perm = user.vault_permissions.find(
        (perm) =>
          perm.user_id === user.id && perm.vault_id === this.value.vault_id,
      );
      if (perm && perm.role_name) {
        userToRoleNameMap[user.id] = perm.role_name;
      }
    });
    return userToRoleNameMap;
  }

  /**
   * teams that can be added to this project
   */
  get availableTeamsToAdd(): Array<WithLabel<TeamSchema>> {
    const {
      value,
      root: {
        accountsStore: {
          teamsMap,
          usersMap,
          teamDetailsMap,
        },
      },
      userToRoleNameMap,
    } = this;

    if (!value) {
      return [];
    }

    const isDisabled = (team: TeamSchema) => {
      // disabled if the current user isn't a vault admin and the team has users that aren't in the vault
      if (!this.currentUserIsVaultAdmin) {
        let result = false;
        const teamDetails = teamDetailsMap[team.id];
        teamDetails.team_memberships.forEach((membership) => {
          const { user_id } = membership;
          const user = usersMap[user_id];
          if (user && !userToRoleNameMap[user_id]) {
            result = true;
          }
        });
        return result;
      }
      return false;
    };

    return Object.values(teamsMap)
      .filter((team) => !value.team_ids.includes(team.id)) // filter out teams that are already in the current project
      .map((team) => ({
        ...team,
        label: `👥 ${team.name}`,
        disabled: isDisabled(team),
        disabledMessage: 'Vault Admin must first add all team members to the Vault',
      }))
      .sort(AccountsUtils.compareNames);
  }

  get availableOptionsToAdd() {
    if (!this.canModifyTeams) {
      return this.availableUsersToAdd;
    }
    return [...this.availableTeamsToAdd, ...this.availableUsersToAdd];
  }

  get teamsInProject() {
    // get all teams that use this project
    return Object.values(this.root.accountsStore.teamsMap).filter((team) =>
      team.project_ids.includes(this.value.id),
    );
  }

  async handleEditProject(project: ProjectSchema) {
    const { usersMap } = this.root.accountsStore;
    const projectUsers = await accountsService.getUsersInProjectWithPermissions(project.id);
    const projectDetails = await accountsService.getProjectDetails(project.id);

    runInAction(() => {
      this.formState = {
        data: { step: 1 }, // skip the details page when editing new
        errors: null,
      };
      this.value = AccountsUtils.convertToEditProject(projectUsers.data, projectDetails.data, usersMap, this.teamsMap);
      this.valueBeforeEdits = JSON.parse(JSON.stringify(this.value));
      this.isOpen = true;
    });
  }

  handleCreateProject() {
    this.formState = {
      data: { step: 0 },
      errors: null,
    };
    this.value = {
      vault_id: this.root.accountsStore.vaultsAndProjectsStore.selectedVaultId,
      project_permissions: {},
      add_users_to_vault: {},
      team_ids: [],
      user_order: [],
    } as EditProjectSchema;
    this.valueBeforeEdits = null;
    this.isOpen = true;
    this.handleAddMember(this.root.environmentStore.currentUser, true);
  }

  handleCancelEdit() {
    this.value = this.valueBeforeEdits = null;
    this.isOpen = false;
  }

  async handleDelete() {
    const reloadOptions = {
      reloadUsers: true,
      reloadProjects: true,
      reloadTeams: true,
      reloadCustomAminoAcids: false,
      reloadCustomNucleotides: false,
    };

    if (await ModalUtils.confirmDelete('this project')) {
      await accountsService.deleteProject(this.value);

      await this.root.accountsStore.reloadData(reloadOptions);
      this.handleCancelEdit();
    }
  }

  async handleSubmit() {
    const { value, valueBeforeEdits, userReadOnlyVaultMap } = this;

    // if any users have readonly vault permissions, we have to save with no edit/manage privileges
    keysAsNumbers(value.project_permissions).forEach(userId => {
      if (userReadOnlyVaultMap[userId]) {
        value.project_permissions[userId].can_edit_data = false;
        value.project_permissions[userId].can_manage_project = false;
      }
    });

    const { usersMap, teamsMap } = this.root.accountsStore;
    const { formatUserName, convertPermissionsToString } = AccountsUtils;
    const updates = diff(
      valueBeforeEdits ?? {},
      value,
    ) as Partial<EditProjectSchema>;

    const reloadOptions = {
      reloadUsers: false,
      reloadProjects: true,
      reloadTeams: false,
      reloadCustomNucleotides: false,
      reloadCustomAminoAcids: false,
    };

    const changes: Array<{
      label: string | string[];
      action: () => Promise<any>;
    }> = [];

    let projectId = this.value.id;

    if (!value.id) {
      const response = await accountsService.createProject(
        value.vault_id,
        value,
      );
      projectId = response.data.id;
    } else {
      const updatePhrases = [];
      if (updates.name !== undefined) {
        updatePhrases.push(
          `Change name from "${valueBeforeEdits?.name}" to "${value.name}".`,
        );
      }
      if (updates.description !== undefined) {
        updatePhrases.push('Update description.');
      }

      if (updatePhrases.length) {
        changes.push({
          label: updatePhrases,
          action: () => {
            return accountsService.updateProject(value);
          },
        });
      }
    }

    if (updates.add_users_to_vault) {
      keysAsNumbers(updates.add_users_to_vault).forEach((userId) => {
        reloadOptions.reloadUsers = true;
        const permissions = {
          ...usersMap[userId],
          ...value.add_users_to_vault[userId],
        };
        const userLabel = formatUserName(usersMap[userId]);
        changes.push({
          label: `Add ${userLabel} to vault with "${permissions.role_name}" role.`,
          action: () => {
            return accountsService.addUserToVault(value.vault_id, permissions);
          },
        });
      });
    }

    if (updates.team_ids) {
      value.team_ids.forEach((newId) => {
        reloadOptions.reloadTeams = true;
        if (!valueBeforeEdits?.team_ids.includes(newId)) {
          changes.push({
            label: `Add team "${teamsMap[newId].name}" to project.`,
            action: () => {
              return accountsService.addProjectToTeam(
                value.id || projectId,
                newId,
              );
            },
          });
        }
      });
      valueBeforeEdits?.team_ids.forEach((oldId) => {
        if (!value.team_ids.includes(oldId)) {
          reloadOptions.reloadTeams = true;
          changes.push({
            label: `Remove team "${teamsMap[oldId].name}" from project.`,
            action: () => {
              return accountsService.removeProjectFromTeam(value, oldId);
            },
          });
        }
      });
    }

    if (updates.project_permissions) {
      keysAsNumbers(updates.project_permissions).forEach((userId) => {
        reloadOptions.reloadUsers = true;
        const permissions = value.project_permissions[userId];
        const userLabel = formatUserName(usersMap[userId]);
        if (permissions === undefined) {
          changes.push({
            label: `Remove ${userLabel} from project.`,
            action: () => {
              return accountsService.removeUserFromProject(projectId, userId);
            },
          });
        } else {
          const permissions = this.value.project_permissions[userId];
          const afterPermissions = convertPermissionsToString(permissions);
          if (this.valueBeforeEdits?.project_permissions[userId]) {
            const beforePermissions = convertPermissionsToString(
              this.valueBeforeEdits.project_permissions[userId],
            );
            changes.push({
              label: `Change ${userLabel} permissions from ${beforePermissions} to ${afterPermissions}.`,
              action: () => {
                return accountsService.updateUserInProject(
                  projectId,
                  userId,
                  permissions,
                );
              },
            });
          } else {
            changes.push({
              label: `Add ${userLabel} to project with ${afterPermissions} permissions.`,
              action: () => {
                return accountsService.addUserToProject(
                  projectId,
                  userId,
                  permissions,
                );
              },
            });
          }
        }
      });
    }

    if (changes.length) {
      const { cancelled, failures } = await AccountsUtils.performChanges(changes, !!value.id);
      if (cancelled || failures) {
        return;
      }
    }

    this.root.accountsStore.reloadData(reloadOptions);

    // end editing
    this.handleCancelEdit();
  }

  handleAddMember(member: UserSchema | TeamSchema, can_edit_data = false) {
    const user = (member as UserSchema).email ? (member as UserSchema) : null;
    const team = user ? null : (member as TeamSchema);

    if (user) {
      if (this.value.user_order.includes(user.id)) {
        if (this.value.project_permissions[user.id].compiled_from_teams) {
          // the user is in the user_order because it belongs to a team that's in the project, but
          // since we're adding it directly to the project, remove it first so it's not added twice
          this.value.user_order = this.value.user_order.filter(id => (id !== user.id));
        }
      }
      if (
        !this.value.project_permissions[user.id] ||
        this.value.project_permissions[user.id].compiled_from_teams
      ) {
        this.value.project_permissions[user.id] = {
          can_edit_data: true,
          can_manage_project: can_edit_data,
          disabled: user.disabled,
        } as any;
        this.value.user_order.unshift(user.id);
      }
    } else {
      this.value.team_ids.push(team.id);
    }
    this.refreshRequiredUserVaultRoles();
  }

  handleRemoveMember(member: UserSchema | TeamSchema) {
    const user = (member as UserSchema).email ? (member as UserSchema) : null;
    const team = user ? null : (member as TeamSchema);

    if (user) {
      delete this.value.project_permissions[user.id];
      this.value.user_order = this.value.user_order.filter(userId => userId !== user.id);
    } else {
      this.value.team_ids = this.value.team_ids.filter((id) => id !== team.id);
    }
    this.refreshRequiredUserVaultRoles();
  }

  // Find all the users in all the selected teams that are not in this project's vault and add them to the field
  // add_users_to_vault, so that the UI will prompt for their vault roles
  refreshRequiredUserVaultRoles() {
    const {
      usersMap,
      teamsStore: { teamDetailsMap },
      vaultsAndProjectsStore: { usersInSelectedVault },
    } = this.root.accountsStore;

    // determine a map of users to existing role names within this vault
    const { userToRoleNameMap } = this;

    const newValue: { [userId: number]: VaultUserCreate } = {};

    // iterate through project's teams, flagging users in those teams who need vault membership
    this.value.team_ids.forEach((teamId) => {
      const teamDetails = teamDetailsMap[teamId];
      // remove any empty values
      if (!teamDetails) {
        delete this.value.team_ids[teamId];
        return;
      }
      teamDetails.team_memberships.forEach((membership) => {
        const { user_id } = membership;
        const user = usersMap[user_id];
        if (user && !userToRoleNameMap[user_id]) {
          newValue[user_id] = (this.value.add_users_to_vault?.[user_id] ?? {
            ...user,
            role_name: '' as RoleName,
          }) as VaultUserCreate;
        }
      });
    });

    // iterate through project's users, flagging those who need vault membership
    keysAsNumbers(this.value.project_permissions).forEach((user_id) => {
      const user = usersMap[user_id];
      if (user && !userToRoleNameMap[user_id]) {
        newValue[user_id] = (this.value.add_users_to_vault?.[user_id] ?? {
          ...user,
          role_name: '' as RoleName,
        }) as VaultUserCreate;
      }
    });

    this.value.add_users_to_vault = newValue;
  }
}
