import { camelCaseKeys, snakeCaseKeys } from '@/shared/utils/camelCaseKeys';
import { camelCase, snakeCase } from 'lodash';
// Types here are utils to transform from the snake_case we use on the ruby side of things to camelCase we use on the typescript side of things
// Note - these are (currently) **Shallow** - ie they only look at the first layer

// change from snake_case to camelCase
type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>;

export type KeysToCamelCase<T extends object> = {
  [K in keyof T as CamelCase<string & K>]: T[K];
};

// change from camelCase to snake_case
type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export type KeysToSnakeCase<T extends object> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K];
};

type KeyMapOptions = { deep: boolean; ignore: string[]; deepIgnore: string[] };

// shallow allows us to use our fancy typing - can add to deep when generic is updated
export function shallowSnakeCaseKeys<TObject extends object>(
  object: TObject,
  options?: KeyMapOptions,
): KeysToSnakeCase<TObject> {
  return snakeCaseKeys(
    object,
    { ...options, deep: false },
    snakeCase,
  ) as KeysToSnakeCase<TObject>;
}

export function shallowCamelCaseKeys<TObject extends object>(
  object: TObject,
  options?: KeyMapOptions,
): KeysToCamelCase<TObject> {
  return camelCaseKeys(
    object,
    { ...options, deep: false },
    camelCase,
  ) as KeysToCamelCase<TObject>;
}
