import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function UndoIcon(props = { id: 'undo-icon', 'disabled': false }) {
  const opacity = props.disabled ? '0.4' : '1'
  return (
    <SvgIcon {...props} viewBox="0 -4 16 24">
      <g opacity={opacity}>
        <path fill="#5B6067" d="M16,12.495c0,0.514-0.053,1.015-0.135,1.505h-2.031C13.939,13.516,14,13.012,14,12.495c0-3.86-3.141-7-7-7
          c-0.34,0-0.672,0.034-1,0.082V10L0,5l6-5v3.56c0.33-0.038,0.662-0.064,1-0.064C11.963,3.495,16,7.533,16,12.495z"/>
      </g>
    </SvgIcon>
  )
}
