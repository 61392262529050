/**
 * This will be the single point of integration between the frontend and the Accounts API
 */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AccountSchema,
  AccountUserPermissions,
  AccountUserSettingsSchema,
  ProjectDetailsSchema,
  TeamSchema,
  TeamWithProjectIdsSchema,
  TeamWithProjectsSchema,
  UserSchema, UsersExportResponse,
  VaultUserWithPermissions,
  VaultWithProjectsSchema,
} from '@/Accounts/types';
import {
  TeamMembership,
  ProjectSchema,
} from './types';
import {
  CustomAminoAcidsSchema,
} from '@/Accounts/CustomAminoAcids/types';
import {
  CustomNucleotidesSchema,
} from '@/Accounts/CustomNucleotides/types';
import { getRootStore } from '@/stores/rootStore';
import { components } from '@/schemas/cdd_openapi_internal';
import { FormDefinition } from '@/Annotator/data/forms';

class http {
  static get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ) {
    return axios.get<T, R>('/api/internal/v1' + url, config);
  }

  static put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.put<T, R>('/api/internal/v1' + url, data, config);
  }

  static post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.post<T, R>('/api/internal/v1' + url, data, config);
  }

  static patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.patch<T, R>('/api/internal/v1' + url, data, config);
  }

  static delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ) {
    return axios.delete<T, R>('/api/internal/v1' + url, config);
  }
}

class AccountsService {
  get accountId() {
    return getRootStore().accountsStore.accountId;
  }

  /**
   * Accounts
   */
  async getAccounts() {
    return http.get<Array<AccountSchema>>('/accounts');
  }

  /**
   * Users
   */

  // Trigger an export of users for this account
  async getAccountExportUsers() {
    return http.get<UsersExportResponse>(`/accounts/${this.accountId}/users/export`);
  }

  // Get a list of users
  async getUsers() {
    return http.get<Array<UserSchema>>(`/accounts/${this.accountId}/users`);
  }

  async getUsersInProject(projectId: number) {
    return http.get<Array<components['schemas']['project_users']>>(
      `/projects/${projectId}/users`,
    );
  }

  async getUsersInProjectWithPermissions(projectId: number) {
    return http.get<components['schemas']['project_users_with_permissions']>(
      `/projects/${projectId}/users/with_permissions`,
    );
  }

  async getUsersWithVaultPermissions() {
    return http
      .get<Array<components['schemas']['user_with_vault_permissions_with_errors']>>(
        `/accounts/${this.accountId}/users/with_permissions`,
      );
  }

  // get a single user with its permissions
  async getUserWithPermissions(user: UserSchema) {
    return http
      .get<AccountUserPermissions>(
        `/accounts/${this.accountId}/users/${user.id}/permissions`,
      )
      .then((response) => {
        return {
          ...response,
          data: {
            ...user,
            ...response.data,
            team_permissions: [],
          },
        };
      });
  }

  async updateAccountUserSettings(user: UserSchema) {
    return http.put<AccountUserSettingsSchema>(
      `/accounts/${this.accountId}/users/${user.id}/settings`,
      user.account_user_settings,
    );
  }

  /**
   * Vaults & Projects
   */

  async getVaultsWithProjects() {
    return http.get<Array<VaultWithProjectsSchema>>(
      `/accounts/${this.accountId}/vaults/with_projects`,
    );
  }

  async getVaultDetails(vaultId: number) {
    return http.get<VaultWithProjectsSchema>(`/vaults/${vaultId}/show_with_projects`);
  }

  async getVaultExportUsers(vaultId: number) {
    return http.get<UsersExportResponse>(`/vaults/${vaultId}/users/export`);
  }

  async getVaultUsers(vault_id: number) {
    return http.get<Array<VaultUserWithPermissions>>(`/vaults/${vault_id}/users`);
  }

  async getVaultUsersWithPermissions(vault_id: number) {
    return http.get<Array<VaultUserWithPermissions>>(`/vaults/${vault_id}/users/with_permissions`);
  }

  async createProject(vault_id: number, project: ProjectSchema) {
    return http.post<components['schemas']['project_with_errors']>(
      `/vaults/${vault_id}/projects`,
      project,
    );
  }

  async updateProject(project: ProjectSchema) {
    return http.put<components['schemas']['project_with_errors']>(
      `/projects/${project.id}`,
      project,
    );
  }

  async deleteProject(project: ProjectSchema | number) {
    const projectId = typeof project === 'number' ? project : project.id;
    return http.delete(`/projects/${projectId}`);
  }

  async addProjectToTeam(project: ProjectSchema | number, team: TeamSchema | number) {
    const projectId = typeof project === 'number' ? project : project.id;
    const teamId = typeof team === 'number' ? team : team.id;

    return http.post(`/teams/${teamId}/projects`, { id: projectId });
  }

  async removeProjectFromTeam(project: ProjectSchema | number, team: TeamSchema | number) {
    const projectId = typeof project === 'number' ? project : project.id;
    const teamId = typeof team === 'number' ? team : team.id;
    return http.delete(`/teams/${teamId}/projects/${projectId}`);
  }

  async getProjectDetails(project: ProjectSchema | number) {
    const projectId = typeof project === 'number' ? project : project.id;
    return http.get<ProjectDetailsSchema>(`/projects/${projectId}`);
  }

  /**
   * Users
   */

  async addUserToVault(
    vault: { id: number; } | number,
    user: Partial<components['schemas']['vault_user_create']>,
  ) {
    const vaultId = typeof vault === 'number' ? vault : vault.id;
    return http.post<UserSchema>(`/vaults/${vaultId}/users`,
      {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        role_name: user.role_name,
        account_user_settings: user.account_user_settings,
      },
    );
  }

  async updateUserInVault(
    vault: { id: number; } | number,
    user: { id: number; } | number,
    permissions: components['schemas']['vault_permissions_create_update'],
  ) {
    const vaultId = typeof vault === 'number' ? vault : vault.id;
    const userId = typeof user === 'number' ? user : user.id;
    return http.put<UserSchema>(`/vaults/${vaultId}/users/${userId}/permissions`, permissions);
  }

  async removeUserFromVault(
    vault: { id: number; } | number,
    user: { id: number; } | number,
  ) {
    const vaultId = typeof vault === 'number' ? vault : vault.id;
    const userId = typeof user === 'number' ? user : user.id;
    return http.delete(`/vaults/${vaultId}/users/${userId}/permissions`);
  }

  async addUserToProject(
    project: { id: number; } | number,
    user: { id: number; } | number,
    permissions: components['schemas']['project_permissions_create_update'],
  ) {
    const projectId = typeof project === 'number' ? project : project.id;
    const userId = typeof user === 'number' ? user : user.id;
    return http.post(
      `/projects/${projectId}/users/${userId}/permissions`,
      permissions,
    );
  }

  async updateUserInProject(
    project: { id: number; } | number,
    user: { id: number; } | number,
    permissions: components['schemas']['project_permissions_create_update'],
  ) {
    const projectId = typeof project === 'number' ? project : project.id;
    const userId = typeof user === 'number' ? user : user.id;
    return http.put(
      `/projects/${projectId}/users/${userId}/permissions`,
      permissions,
    );
  }

  async removeUserFromProject(
    project: { id: number; } | number,
    user: { id: number; } | number,
  ) {
    const projectId = typeof project === 'number' ? project : project.id;
    const userId = typeof user === 'number' ? user : user.id;
    return http.delete(`/projects/${projectId}/users/${userId}/permissions`);
  }

  /**
   * Teams
   */
  async getTeams() {
    return http.get<Array<TeamSchema>>(`/accounts/${this.accountId}/teams`);
  }

  async getTeamsWithProjectIds() {
    return http.get<Array<TeamWithProjectIdsSchema>>(`/accounts/${this.accountId}/teams/with_project_ids`);
  }

  async getTeamDetails(team: { id: number; } | number) {
    const teamId = (typeof team === 'number') ? team : team.id;
    return http.get<TeamWithProjectsSchema>(`/teams/${teamId}`);
  }

  async createTeam(team: TeamSchema) {
    const payload: components['schemas']['team_create_update'] = {
      name: team.name,
    };
    return http.post<components['schemas']['team_with_errors']>(
      `/accounts/${this.accountId}/teams`,
      { team: payload },
    );
  }

  async updateTeam(team: TeamSchema) {
    const payload: components['schemas']['team_create_update'] = {
      name: team.name,
    };
    return http.put<components['schemas']['team_with_errors']>(
      `/teams/${team.id}}`,
      { team: payload },
    );
  }

  async addUserToTeam(team: TeamSchema | number, permissions: TeamMembership) {
    const teamId = typeof team === 'number' ? team : team.id;

    return http.post<components['schemas']['team_with_errors']>(
      `/teams/${teamId}/users`,
      {
        ...permissions,
        id: permissions.user_id,
        can_edit_data: !!permissions.can_edit_data,
        can_manage_project: !!permissions.can_manage_project,
        user: {
          id: permissions.user_id,
          can_edit_data: permissions.can_edit_data,
          can_manage_project: permissions.can_manage_project,
        },
      },
    );
  }

  async removeUserFromTeam(team: TeamSchema | number, user: UserSchema | number) {
    const teamId = typeof team === 'number' ? team : team.id;
    const userId = typeof user === 'number' ? user : user.id;
    return http.delete<components['schemas']['team_with_errors']>(
      `/teams/${teamId}/users/${userId}`,
    );
  }

  async changeUserMembership(team: TeamSchema | number, permissions: TeamMembership) {
    const teamId = typeof team === 'number' ? team : team.id;

    return http.put<components['schemas']['team_with_errors']>(`/teams/${teamId}/users/${permissions.user_id}`, {
      ...permissions,
      id: permissions.user_id,
      can_edit_data: !!permissions.can_edit_data,
      can_manage_project: !!permissions.can_manage_project,
      user: {
        id: permissions.user_id,
        can_edit_data: permissions.can_edit_data,
        can_manage_project: permissions.can_manage_project,
      },
    });
  }

  async deleteTeam(team: TeamSchema | number) {
    const teamId = typeof team === 'number' ? team : team.id;
    return http.delete(`/teams/${teamId}`);
  }

  /**
   * CustomNucleotides
   */
  async getCustomNucleotides() {
    return http.get<Array<CustomNucleotidesSchema>>(`/accounts/${this.accountId}/custom_nucleotides`);
  }

  async getCustomNucleotide(customNucleotide: CustomNucleotidesSchema) {
    return http
      .get<CustomNucleotidesSchema>(
        `/accounts/${this.accountId}/custom_nucleotides/${customNucleotide.id}`,
      )
      .then((response) => {
        return {
          ...response,
          data: {
            ...customNucleotide,
            ...response.data,
          },
        };
      });
  }

  async createCustomNucleotide(customNucleotide: CustomNucleotidesSchema) {
    return http.post<components['schemas']['custom_nucleotide_with_errors']>(
      `/accounts/${this.accountId}/custom_nucleotides`,
      customNucleotide,
    );
  }

  async updateCustomNucleotide(customNucleotide: CustomNucleotidesSchema) {
    return http.put<components['schemas']['custom_nucleotide_with_errors']>(
      `/accounts/${this.accountId}/custom_nucleotides/${customNucleotide.id}`,
      customNucleotide,
    );
  }

  /**
   * CustomAminoAcids
   */
  async getCustomAminoAcids() {
    return http.get<Array<CustomAminoAcidsSchema>>(`/accounts/${this.accountId}/custom_amino_acids`);
  }

  async getCustomAminoAcid(customAminoAcid: CustomAminoAcidsSchema) {
    return http
      .get<CustomAminoAcidsSchema>(
          `/accounts/${this.accountId}/custom_amino_acids/${customAminoAcid.id}`,
      )
      .then((response) => {
        return {
          ...response,
          data: {
            ...customAminoAcid,
            ...response.data,
          },
        };
      });
  }

  async createCustomAminoAcid(customNucleotide: CustomAminoAcidsSchema) {
    return http.post<components['schemas']['custom_amino_acid_with_errors']>(
        `/accounts/${this.accountId}/custom_amino_acids`,
        customNucleotide,
    );
  }

  async updateCustomAminoAcid(customAminoAcid: CustomAminoAcidsSchema) {
    return http.put<components['schemas']['custom_amino_acid_with_errors']>(
        `/accounts/${this.accountId}/custom_amino_acids/${customAminoAcid.id}`,
        customAminoAcid,
    );
  }

  /**
   * FormDefinitions
   */
  async getFormDefinitions(vaultId: number) {
    return http.get<Array<components['schemas']['form_definition']>>(`/vaults/${vaultId}/form_definitions`);
  }

  async createFormDefinition(vaultId: number, formDefinition: FormDefinition) {
    const payload: components['schemas']['form_definition_create_update'] = {
      name: formDefinition.name,
      protocol_form: JSON.parse(JSON.stringify(formDefinition.protocol_form)),
      readout_form: JSON.parse(JSON.stringify(formDefinition.readout_form)),
      run_form: JSON.parse(JSON.stringify(formDefinition.run_form)),
    };

    return http.post<components['schemas']['form_definition']>(
        `/vaults/${vaultId}/form_definitions`,
        { form_definition: payload },
    );
  }

  async updateFormDefinition(vaultId: number, formDefinition: FormDefinition) {
    const payload: components['schemas']['form_definition_create_update'] = {
      name: formDefinition.name,
      protocol_form: JSON.parse(JSON.stringify(formDefinition.protocol_form)),
      readout_form: JSON.parse(JSON.stringify(formDefinition.readout_form)),
      run_form: JSON.parse(JSON.stringify(formDefinition.run_form)),
    };

    return http.put<components['schemas']['form_definition']>(
        `/vaults/${vaultId}/form_definitions/${formDefinition.id}`,
        { form_definition: payload },
    );
  }

  async deleteFormDefinition(vaultId: number, formDefinition: FormDefinition) {
    const url = `/vaults/${vaultId}/form_definitions/${formDefinition.id}`;
    return http.delete(url);
  }
}

export const accountsService = new AccountsService();
