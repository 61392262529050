import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function QuestionIcon(props = { id: 'question-icon', 'disabled': false, color: '#5B6067' }) {
  const opacity = props.disabled ? '0.4' : '1'
  return (
    <SvgIcon {...props} viewBox="0 0 5.2916668 5.2916668">
      <g opacity={opacity} fill={props.color}>
        <path d="m 2.6464844,0.11132812 c -1.3979841,-4e-8 -2.53515632,1.13717218 -2.53515628,2.53515628 0,1.3979841 1.13717218,2.5332031 2.53515628,2.5332031 1.397984,0 2.5332031,-1.1352191 2.5332031,-2.5332031 0,-1.3979841 -1.135219,-2.53515628 -2.5332031,-2.53515628 z m 0,0.26562501 c 1.2549929,0 2.2695313,1.01453827 2.2695312,2.26953127 0,1.2549929 -1.0145383,2.2695312 -2.2695312,2.2695312 -1.254993,1e-7 -2.26953128,-1.0145383 -2.26953127,-2.2695312 -4e-8,-1.254993 1.01453827,-2.26953131 2.26953127,-2.26953127 z"/>
        <path d="m 3.9681615,1.7861631 q 0,0.2458768 -0.073033,0.4357618 Q 3.8245305,2.4093756 3.6930716,2.5481377 3.5616128,2.6868999 3.3765965,2.796449 3.1940148,2.9059981 2.9627445,2.9960717 V 3.5511204 H 2.1058273 V 2.7307195 q 0.1728441,-0.046254 0.3116062,-0.094943 0.1411966,-0.048688 0.2945653,-0.1582375 0.143631,-0.097377 0.223967,-0.2264015 0.08277,-0.1290244 0.08277,-0.2921308 0,-0.2434424 -0.1582375,-0.3456882 Q 2.7046955,1.5086387 2.419868,1.5086387 q -0.1752786,0 -0.3968111,0.075467 -0.2190982,0.075467 -0.40168,0.1947539 H 1.524 V 1.0363605 q 0.1558031,-0.0657294 0.4795815,-0.13632773 0.3237783,-0.0730327 0.6572944,-0.0730327 0.6013027,0 0.9542942,0.26535225 0.3529914,0.2653522 0.3529914,0.6938108 z M 3.0309083,4.5248899 H 2.0474011 V 3.882202 h 0.9835072 z"/>
      </g>
    </SvgIcon>
  )
}
