/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import { AccountsUtils } from '@/Accounts/accountsUtils';
import {
  ProjectPermissions,
  UserSchema,
  EditUserSchema,
  VaultPermissions,
} from '@/Accounts/types';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { RootStore } from '@/stores/rootStore';
import { diff } from 'deep-object-diff';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { accountsService } from '../../accountsServices';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { ProjectSchema } from '../../types';
import { accountUserSettingsHumanMapping } from '../components/constants';

export class EditUserStore {
  inited = false;
  disposers: Array<() => void> = [];
  isOpen = false;
  value: EditUserSchema = null; // the value currently being edited
  valueBeforeEdits: EditUserSchema = null; // a copy of the value as loaded
  formState: {
    data: {
      step: number;
      filterByVault?: string;
    };
    errors: { [key: string]: string; } | null;
  } = {
      data: { step: 0 },
      errors: null,
    };

  constructor(public readonly root: RootStore) {
    this.root = root;
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!this.inited) {
      this.inited = true;

      this.disposers.push(
        // when the current user's team membership changes, add empty vault memberships for any
        // referenced vaults that the user is not already a part of
        reaction(
          () => this.isOpen && JSON.stringify(this.value?.team_memberships),
          () => {
            keysAsNumbers(this.value?.team_memberships).forEach((teamId) => {
              const {
                teamsStore: { teamsMap },
                vaultsAndProjectsStore: { projectsIdToVaultIdMap },
              } = this.root.accountsStore;

              const team = teamsMap[teamId];
              if (team) {
                team.project_ids.forEach((id) => {
                  const vaultId = projectsIdToVaultIdMap[id];
                  if (this.value.vault_permissions === undefined) {
                    this.value.vault_permissions = {};
                  }

                  if (this.value.vault_permissions[vaultId] === undefined) {
                    this.value.vault_permissions[vaultId] =
                      {} as VaultPermissions;
                  }
                });
              }
            });
          },
          { fireImmediately: true },
        ),
      );
    }
  }

  /**
   * For the user currently being edited, get a list of all the projects that it's in and the additive permissions
   * for each project.
   *
   * A user can either be in a project through team membership or directly added to the project.
   */
  get projectPermissions() {
    const result: {
      [projectId: number]: {
        teams: Array<number>;
      } & Partial<ProjectPermissions>;
    } = {};

    const { team_memberships: team_permissions, project_permissions } =
      this.value;
    const { teamsMap } = this.root.accountsStore.teamsStore;

    // get an additive list of permissions for all projects in all teams
    keysAsNumbers(team_permissions).forEach((teamId) => {
      const team = teamsMap[teamId];
      const permissions = team_permissions[teamId];

      team.project_ids.forEach((id) => {
        const teams = (result[id]?.teams ?? []).concat([team.id]);
        result[id] = {
          compiled_from_teams: true,
          teams,
          can_edit_data:
            permissions.can_edit_data || !!result[id]?.can_edit_data,
          can_manage_project:
            permissions.can_manage_project || !!result[id]?.can_manage_project,
        };
      });
    });

    // the user might also be directly added to a project
    keysAsNumbers(project_permissions).forEach((projectId) => {
      const userPermissions = project_permissions[projectId];
      result[projectId] = {
        compiled_from_teams: false,
        teams: [],
        can_edit_data: !!userPermissions.can_edit_data,
        can_manage_project: !!userPermissions.can_manage_project,
      };
    });

    return result;
  }

  get prohibitedEmails() {
    // get the list of existing user emails
    const { users } = this.root.accountsStore.usersStore;
    return users
      .map((user) => user.email)
      .filter((email) => email !== this.valueBeforeEdits?.email); // if editing an existing team, remove the current name from the prohibited list
  }

  get prohibitedIdentifiersLowercase() {
    // get the list of existing user identifiers
    const { users } = this.root.accountsStore.usersStore;
    return users
      .filter(user => user.id !== this.valueBeforeEdits?.id) // ignore current user
      .map(user => user.account_user_settings?.identifier?.toLowerCase())
      .filter(identifier => identifier); // ignore null
  }

  get vaultsMap() {
    return this.root.accountsStore.vaultsAndProjectsStore.vaultsMap;
  }

  get projectsMap() {
    return this.root.accountsStore.vaultsAndProjectsStore.projectsMap;
  }

  get isEditingSelf() {
    const currentUserId = this.root.environmentStore.currentUser.id;
    return currentUserId === this.value.id;
  }

  get availableVaultsToAdd() {
    const {
      vaultsAndProjectsStore: { vaultsMap },
    } = this.root.accountsStore;

    const usedVaultIds = new Set<number>(
      this.currentVaults.map((vault) => vault.id),
    );

    return Object.values(vaultsMap).filter(
      (vault) => !usedVaultIds.has(vault.id),
    )
      .sort(AccountsUtils.compareNames);
  }

  get currentVaults() {
    const {
      vaultsAndProjectsStore: { vaultsMap },
    } = this.root.accountsStore;

    const usedVaultIds = new Set<number>(
      Array.from(this.teamRequiredVaultIds).concat(
        keysAsNumbers(this.value.vault_permissions),
      ),
    );

    return Array.from(usedVaultIds)
      .map((vaultId) => vaultsMap[vaultId])
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Set of vault ids that are required through the user's team membership
   */
  get teamRequiredVaultIds() {
    const result = new Set<number>();
    const {
      teamsStore: { teamsMap },
      vaultsAndProjectsStore: { projectsIdToVaultIdMap },
    } = this.root.accountsStore;

    keysAsNumbers(this.value?.team_memberships ?? {}).forEach((teamId) => {
      const team = teamsMap[teamId];
      if (team) {
        team.project_ids.forEach((id) => {
          const vaultId = projectsIdToVaultIdMap[id];
          result.add(vaultId);
        });
      }
    });
    return result;
  }

  get selectedVaultsWithProjects() {
    const {
      teamsStore: { teamsMap },
      vaultsAndProjectsStore: { projectsIdToVaultIdMap, vaultsMap },
    } = this.root.accountsStore;

    const sortedVaults = keysAsNumbers(this.value.vault_permissions)
      .map((vaultId) => vaultsMap[vaultId])
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

    const filterByVault =
      this.formState.data.filterByVault?.toLocaleLowerCase();

    // for each vault, generate a section of project permissions
    return sortedVaults
      .filter((vault) => {
        if (filterByVault) {
          return (vault.name?.toLocaleLowerCase() ?? '').includes(
            filterByVault,
          );
        }
        return true;
      })
      .filter((vault) => {
        return vault.projects.length;
      });
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    --this.root.accountsStore.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.root.accountsStore.loading;
    this.root.decrementLoading();
  }

  async handleEditUser(user: UserSchema) {
    this.incrementLoading();
    try {
      const response = await accountsService.getUserWithPermissions(user);
      runInAction(() => {
        this.formState = {
          data: { step: 1 }, // skip the details page when editing new
          errors: null,
        };
        const { teamsMap, projectsMap, vaultsMap } = this.root.accountsStore;
        this.value = AccountsUtils.convertToEditUser(response.data, teamsMap, projectsMap, vaultsMap);
        this.valueBeforeEdits = JSON.parse(JSON.stringify(this.value));
        this.isOpen = true;
      });
    } finally {
      this.decrementLoading();
    }
  }

  handleNewUser() {
    this.value = {
      vault_permissions: {},
    } as EditUserSchema;
    this.valueBeforeEdits = null;
    this.formState = {
      data: { step: 0 }, // start on the details page when editing new
      errors: null,
    };
    this.isOpen = true;
  }

  handleAddTeam(id: number) {
    this.value.team_memberships = {
      ...this.value.team_memberships,
      [id]: {
        can_edit_data: false,
        can_manage_project: false,
      },
    };
  }

  handleAddVault(id: number) {
    this.value.vault_permissions = {
      ...this.value.vault_permissions,
      [id]: {
        role_name: this.isEditingSelf ? 'vault admin' : '',
      },
    } as VaultPermissions;
  }

  handleRemoveVault(id: number) {
    delete this.value.vault_permissions[id];
  }

  handleAddProject(project: ProjectSchema) {
    this.value.project_permissions = {
      ...this.value.project_permissions,
      [project.id]: {
        compiled_from_teams: false,
        can_edit_data: true,
        can_manage_project: false,
      },
    } as ProjectPermissions;
  }

  handleRemoveProject(project: ProjectSchema) {
    delete this.value.project_permissions[project.id];
  }

  handleCancelEdit() {
    this.isOpen = false;
  }

  async handleSubmit() {
    const { value, valueBeforeEdits } = this;
    const {
      vaultsMap,
      projectsMap,
      teamsMap,
      projectsIdToVaultIdMap,
    } = this.root.accountsStore;
    const { convertPermissionsToString } = AccountsUtils;

    const reloadOptions = {
      reloadUsers: true,
      reloadProjects: false,
      reloadTeams: false,
      reloadCustomNucleotides: false,
      reloadCustomAminoAcids: false,
    };

    // ensure that any projects in read-only vaults have no edit/manage flags
    keysAsNumbers(value.project_permissions).forEach(projectId => {
      const vaultId = projectsIdToVaultIdMap[projectId];
      if (AccountsUtils.isReadonlyVaultRole(value.vault_permissions[vaultId]?.role_name)) {
        value.project_permissions[projectId].can_edit_data =
          value.project_permissions[projectId].can_manage_project = false;
      }
    });

    const updates = diff(
      valueBeforeEdits ?? {},
      value,
    ) as Partial<EditUserSchema>;

    const changes: Array<{
      label: string | string[];
      action: () => Promise<any>;
    }> = [];

    let userId = this.value.id;

    const vaultIds = keysAsNumbers(updates.vault_permissions ?? {});

    const createAddToVaultPromise = (vaultId: number) => {
      return accountsService.addUserToVault(vaultId, {
        email: this.value.email,
        first_name: this.value.first_name,
        last_name: this.value.last_name,
        role_name: this.value.vault_permissions[vaultId].role_name,
        account_user_settings: {
          identifier: this.value.account_user_settings?.identifier,
        },
      });
    };

    // update vault membership
    if (!value.id) {
      // create user
      if (!vaultIds.length) {
        await ModalUtils.showModal(
          'User must be added to at least one vault.',
          { noCancelOption: true },
        );
        return false;
      }
      for (let i = 0; i < vaultIds.length; i++) {
        const response = await createAddToVaultPromise(vaultIds[i]);
        userId = response.data.id;
      }
    } else {
      // For now, we're not going to allow changing the user's name through this UI. The API does
      // not exist. But if it is allowed in the future, we might do it like this:

      // if (updates.first_name !== undefined || updates.last_name !== undefined) {
      //   const oldName = valueBeforeEdits.first_name + ' ' + valueBeforeEdits.last_name;
      //   const newName = value.first_name + ' ' + value.last_name;
      //   changes.push({
      //     label: `Change user name from "${oldName}" to "${newName}".`,
      //     action: () => {
      //       return accountsService.updateUser()
      //     },
      //   });
      // }

      vaultIds.forEach((vaultId) => {
        const roleName = value.vault_permissions[vaultId]?.role_name;
        const vaultName = vaultsMap[vaultId]?.name ?? '';
        if (updates.vault_permissions[vaultId] === undefined) {
          changes.push({
            label: `Remove user from vault "${vaultName}".`,
            action: () => {
              return accountsService.removeUserFromVault(vaultId, userId);
            },
          });
        } else if (!valueBeforeEdits?.vault_permissions[vaultId]) {
          changes.push({
            label: `Add user to vault "${vaultName}" with role "${roleName}".`,
            action: () => {
              return createAddToVaultPromise(vaultId);
            },
          });
        } else {
          const oldRoleName =
            valueBeforeEdits?.vault_permissions[vaultId].role_name;
          changes.push({
            label: `Change user role on vault "${vaultName}" to role "${roleName}" from "${oldRoleName}".`,
            action: () => {
              return accountsService.updateUserInVault(
                vaultId,
                userId,
                value.vault_permissions[vaultId],
              );
            },
          });
        }
      });
    }

    // update project permissions
    if (updates.project_permissions) {
      keysAsNumbers(updates.project_permissions).forEach((projectId) => {
        reloadOptions.reloadProjects = true;
        const project = projectsMap[projectId];
        const permissions = value.project_permissions[projectId];
        if (permissions === undefined) {
          changes.push({
            label: `Remove user from project "${project.name}".`,
            action: () => {
              return accountsService.removeUserFromProject(projectId, userId);
            },
          });
        } else {
          const afterPermissions = convertPermissionsToString(permissions);
          if (valueBeforeEdits?.project_permissions[projectId]) {
            const beforePermissions = convertPermissionsToString(
              valueBeforeEdits.project_permissions[projectId],
            );
            changes.push({
              label: `Change user's permissions on project "${project.name}" from ${beforePermissions} to ${afterPermissions}.`,
              action: () => {
                return accountsService.updateUserInProject(
                  projectId,
                  userId,
                  permissions,
                );
              },
            });
          } else {
            changes.push({
              label: `Add user to project "${project.name}" with ${afterPermissions} permissions.`,
              action: () => {
                return accountsService.addUserToProject(
                  projectId,
                  userId,
                  permissions,
                );
              },
            });
          }
        }
      });
    }

    // update team permissions
    if (updates.team_memberships) {
      keysAsNumbers(updates.team_memberships).forEach((teamId) => {
        const team = teamsMap[teamId];

        reloadOptions.reloadTeams = true;

        if (updates.team_memberships[teamId] === undefined) {
          changes.push({
            label: `Remove user from team "${team.name}".`,
            action: () => {
              return accountsService.removeUserFromTeam(teamId, userId);
            },
          });
        } else {
          const membership = {
            ...value.team_memberships[teamId],
            user_id: userId,
          };
          const permissions = convertPermissionsToString(membership);
          if (!valueBeforeEdits?.team_memberships[teamId]) {
            changes.push({
              label: `Add user to team "${team.name}" with ${permissions} permissions.`,
              action: () => {
                return accountsService.addUserToTeam(teamId, membership);
              },
            });
          } else {
            const old = convertPermissionsToString(
              valueBeforeEdits[userId] ?? {},
            );
            changes.push({
              label: `Change user permissions on team "${team.name}" from ${old} with ${permissions} permissions.`,
              action: () => {
                return accountsService.changeUserMembership(teamId, membership);
              },
            });
          }
        }
      });
    }

    // update user settings, create is done when the user is created
    if (updates.account_user_settings && value?.id) {
      const humanChanges = Object.keys(updates.account_user_settings).map(key => {
        const old = (valueBeforeEdits?.account_user_settings && valueBeforeEdits.account_user_settings[key]) || '';
        const current = value.account_user_settings[key] || '';
        return `"${accountUserSettingsHumanMapping[key] || key}" from "${old}" to "${current}"`;
      }).join(',');

      if (humanChanges) {
        changes.push({
          label: `Change settings ${humanChanges}`,
          action: () => {
            return accountsService.updateAccountUserSettings(value);
          },
        });
      }
    }

    const sortByLabel = [
      'Add user to vault',
      'Change settings',
      'Change user role on vault',
      'Add user to project',
      'Change user\'s permissions on project',
      'Add user to team',
      'Change user permissions on team',
      'Remove user from project',
      'Remove user from team',
      'Remove user from vault',
    ];

    changes.sort((a, b) => {
      const getIndex = a => {
        for (let i = 0; i < sortByLabel.length; i++) {
          if (a.label.startsWith(sortByLabel[i])) {
            return i;
          }
        }
        return -1;
      };
      return getIndex(a) - getIndex(b);
    });

    if (changes.length) {
      const { cancelled, failures } = await AccountsUtils.performChanges(changes, !!value.id);
      if (cancelled || failures) {
        return;
      }
    }

    this.root.accountsStore.reloadData(reloadOptions);
    this.handleCancelEdit();
  }
}
