/*
  Datastructures for protocol forms, which combine protocol fields and ontology template terms.
*/

import { ProtocolField } from './protocol_fields';
import { OntologyTemplate, TemplateGroup } from './templates';
import { keyPropGroup } from './utils';
import { Annotation } from './annotations';
import { Terminology } from 'javascripts/cross_app_utilities.js';

export interface FormDefinition {
  id?: number;
  data_set_id?: number;
  name?: string;
  created_at?: string;
  updated_at?: string;

  protocol_form?: ProtocolForm; // eslint-disable-line no-use-before-define
  readout_form?: ProtocolForm; // eslint-disable-line no-use-before-define
  run_form?: ProtocolForm; // eslint-disable-line no-use-before-define
}
export interface ProtocolForm {
  schemaPrefix?: string; // indicates the ontology template that it's based on, if any
  sections: FormSection[]; // eslint-disable-line no-use-before-define
}

export interface FormSection {
  name: string;
  description?: string;
  contents: FormLayout[]; // eslint-disable-line no-use-before-define
}

export enum FormLayoutType {
  Table = 'table',
  Row = 'row',
  Cell = 'cell',
}

export enum FormContextType {
  Protocol, // for entry during the protocol configuration stage
  Run, // for entry during the run configuration stage
}

export enum FormLayoutEmphasis {
  Underline,
}

//  id of a protocol or run field, depending on the form it's on, or a special required field.
export type FieldIdType = number | 'protocol_name' | 'run_date' | string;

export interface FormLayout {
  layoutType: FormLayoutType;
  span?: number;
  label?: string;
  emphasis?: FormLayoutEmphasis[];
  ontologyAssn?: string[]; // reference to an ontology assignment [propURI, groupNest...]
  ontologyAnnots?: Annotation[]; // valueURI annotation term(s)
  fieldID?: FieldIdType;
  context?: FormContextType;
  contents?: FormLayout[];
  defaultValue?: string;
  isLocked?: boolean;
  isRequired?: boolean;
  allowedValues?: (string|number)[]; // for pick lists
}

// manufactures an "implied form" which has the default available content with a default layout
export function createImpliedForm(formContext: FormContextType, protocolFields: ProtocolField[], ontologyTemplate: OntologyTemplate, ontologyAnnotations: Annotation[]): ProtocolForm {
  const form: ProtocolForm = {
    schemaPrefix: ontologyTemplate ? ontologyTemplate.schemaPrefix : null,
    sections: [],
  };

  // setup the incoming semantic content for convenient reference
  const mapAnnot = new Map<string, Annotation[]>();
  for (const annot of ontologyAnnotations) {
    const key = keyPropGroup(annot.propURI, annot.groupNest);
    mapAnnot.set(key, [...(mapAnnot.get(key) || []), annot]);
  }

  const strTitle = (formContext === FormContextType.Protocol)
    ? Terminology.dictionary.protocol.titleize()
    : Terminology.dictionary.run.titleize();

  // create a section for protocol fields, if any
  if (protocolFields.length > 0) {
    const sectionName = ontologyTemplate ? `${strTitle} Fields` : null;
    const section: FormSection = {
      name: sectionName,
      contents: [
        {
          layoutType: FormLayoutType.Table,
          context: formContext,
          contents: [],
        },
      ],
    };
    form.sections.push(section);

    const tableRows = section.contents[0].contents;
    for (const field of protocolFields) {
      const isRequired = field.definition?.required_group_number || !field.definition;
      const row: FormLayout = {
        layoutType: FormLayoutType.Row,
      };
      tableRows.push(row);

      const cellName: FormLayout = {
        layoutType: FormLayoutType.Cell,
        label: isRequired ? `${field.label} *` : field.label,
      };

      const fieldID = field?.definition?.id ?? ((formContext === FormContextType.Protocol)
        ? 'protocol_name'
        : 'run_date');

      const cellValue: FormLayout = {
        layoutType: FormLayoutType.Cell,
        fieldID,
      };
      row.contents = [cellName, cellValue];
    }
  }

  // create a section for the schema, if designated
  if (ontologyTemplate) {
    const section: FormSection = {
      name: 'Ontology Template',
      contents: [
        {
          layoutType: FormLayoutType.Table,
          context: formContext,
          contents: [],
        },
      ],
    };
    form.sections.push(section);

    appendOntologyGroup(section, ontologyTemplate.root, [], mapAnnot);

    // TODO: create orphan group if any left...
  }

  return form;
}

function appendOntologyGroup(section: FormSection, group: TemplateGroup, groupNest: string[], mapAnnot: Map<string, Annotation[]>): void {
  const tableRows = section.contents[0].contents;

  // create a row for just this assignment

  for (const assn of (group.assignments || [])) {
    const row: FormLayout = {
      layoutType: FormLayoutType.Row,
    };
    tableRows.push(row);

    const key = keyPropGroup(assn.propURI, groupNest);

    const cellName: FormLayout = {
      layoutType: FormLayoutType.Cell,
      label: assn.name + ':',
      ontologyAssn: [assn.propURI, ...groupNest],
    };
    const cellValue: FormLayout = {
      layoutType: FormLayoutType.Cell,
      ontologyAssn: [assn.propURI, ...groupNest],
      ontologyAnnots: mapAnnot.get(key),
    };
    row.contents = [cellName, cellValue];

    mapAnnot.delete(key);
  }

  // iterate over any sub-groups and call recursively

  for (const sgrp of (group.subGroups || [])) {
    const row: FormLayout = {
      layoutType: FormLayoutType.Row,
    };
    tableRows.push(row);

    const cellHeading: FormLayout = {
      layoutType: FormLayoutType.Cell,
      span: 2,
      label: sgrp.name,
      emphasis: [FormLayoutEmphasis.Underline],
    };
    row.contents = [cellHeading];

    appendOntologyGroup(section, sgrp, [sgrp.groupURI, ...groupNest], mapAnnot);
  }
}

// go through the sections of a form and replace any ontology definitions with those given in the parameters
export function replaceFormTerms(form: ProtocolForm, annotations: Annotation[]): void {
  const mapAnnots = new Map<string, Annotation[]>();

  for (const annot of annotations) {
    const key = keyPropGroup(annot.propURI, annot.groupNest);
    mapAnnots.set(key, [...(mapAnnots.get(key) || []), annot]);
  }

  const scanLayout = (layout: FormLayout): void => {
    if (layout.ontologyAssn) {
      const key = keyPropGroup(layout.ontologyAssn[0], layout.ontologyAssn.slice(1));
      layout.ontologyAnnots = mapAnnots.get(key);
    }

    if (layout.contents) {
      for (const sub of layout.contents) {
        scanLayout(sub);
      }
    }
  };
  for (const section of form.sections) {
    for (const layout of section.contents) {
      scanLayout(layout);
    }
  }
}
