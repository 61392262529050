import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function PlusIcon(props = { id: 'plus-icon', 'disabled': false }) {
  const opacity = props.disabled ? '0.4' : '1'
  return (
    <SvgIcon {...props} viewBox="-2 -2 22 22">
      <g opacity={opacity} transform="translate(-91.191333,-39.187272)">
        <path fill="#5B6067" d="M 99.191361 40.912231 L 99.191361 47.1873 L 92.915776 47.1873 L 92.915776 49.187695 L 99.191361 49.187695 L 99.191361 55.462764 L 101.19124 55.462764 L 101.19124 49.187695 L 107.46683 49.187695 L 107.46683 47.1873 L 101.19124 47.1873 L 101.19124 40.912231 L 99.191361 40.912231 z"/>
      </g>
    </SvgIcon>
  )
}
