import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Icon } from '@mui/material';

type Props = {
  value: string;
}
export default class CopyToClipboardButton extends React.Component<Props> {
  state = {
    value: '',
    copied: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      copied: false,
    };
  }

  render() {
    return (
      <CopyToClipboard text={this.state.value} onCopy={() => this.setState({ copied: true })}>
        <Button variant='outlined'>
        <Icon className={this.state.copied ? 'fa fa-check' : 'fa fa-clipboard'} />
        &nbsp;&nbsp;
        {this.state.copied ? 'Copied!' : 'Copy to clipboard'}
        </Button>
      </CopyToClipboard>
    );
  }
}
