import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function MagnifyIcon(props = { id: 'magnify-icon', 'disabled': false }) {
  const opacity = props.disabled ? '0.4' : '1'
  return (
    <SvgIcon {...props} viewBox="-2 -2 22 22">
      <g opacity={opacity} transform="translate(-91.191333,-39.187272)">
        <path fill="#5B6067" d="m 101.6582,40.095703 c -3.140357,0.09463 -5.889275,2.836108 -5.999997,5.974609 -0.07803,1.422407 0.347681,2.859581 1.175781,4.017579 -1.529948,1.529297 -3.059895,3.058593 -4.589843,4.58789 -0.177422,0.651677 0.275785,1.196071 0.807031,1.457031 0.702207,0.206576 1.051698,-0.438639 1.498709,-0.848347 1.255109,-1.229777 2.503015,-2.551504 3.762619,-3.723918 2.49658,1.855865 6.31891,1.435202 8.34586,-0.925536 2.1725,-2.288974 2.15844,-6.212411 -0.0296,-8.486328 -1.24281,-1.381487 -3.11604,-2.148707 -4.97055,-2.05298 z m 0.75391,2.074219 c 2.3824,0.181179 4.2393,2.633967 3.7528,4.976136 -0.34156,2.412213 -3.02028,4.089402 -5.34259,3.364847 -2.362839,-0.577926 -3.766946,-3.403369 -2.823942,-5.640727 0.64724,-1.769353 2.547242,-2.913497 4.413732,-2.700256 z"/>
      </g>
    </SvgIcon>
  )
}
