/*
  Datastructures for ontology templates.
*/

// NOTE: the "suggestion type" is a legacy carryover, that is used to provide guidance as to what kind of content is wanted, and how the
// user should be guided toward entering it
export const enum SuggestionType {
  Full = 'full', // URI type: requests models to be built for suggesting terms (if possible)
  Disabled = 'disabled', // URI type: but with models not wanted
  Field = 'field', // label type: refers to a structure-activity response value, typically numeric
  URL = 'url', // label type: URL link
  ID = 'id', // label type: internal identifier system
  String = 'string', // label type: freeform string
  Number = 'number', // label type: any numeric representation
  Integer = 'integer', // label type: integer subset
  Date = 'date', // label type: should represent a date (YYYY-MM-DD)
}

export enum SpecificationType {
  Item = 'item',
  Exclude = 'exclude',
  WholeBranch = 'wholebranch',
  ExcludeBranch = 'excludebranch',
  Container = 'container',
}

export interface TemplateValue {
  uri: string;
  name: string;
  descr?: string;
  altLabels?: string[];
  externalURLs?: string[];
  spec: SpecificationType;
  parentURI?: string;
}

export interface TemplateAssignment {
  name: string;
  descr?: string;
  propURI: string;
  suggestions: SuggestionType;
  mandatory: boolean;
  values: TemplateValue[];
}

export interface TemplateGroup {
  name: string;
  descr?: string;
  groupURI: string;
  canDuplicate?: boolean;
  assignments: TemplateAssignment[];
  subGroups: TemplateGroup[];
}

export interface OntologyTemplate {
  schemaPrefix: string; // universal identifier for the template, which can also be used to prefix implied URIs
  branchGroups?: string[]; // if this is a branch template, optional list of group URIs that this template can be nested under
  root: TemplateGroup;
}
