import React from 'react'

const SIZES = {
  small: {
    height: 16,
    width: 16,
  },
}

export default function SvgIcon({ size, ...props } = {
  size: null,
}) {
  const style = { enableBackground: 'new 0 0 32 32' }

  let sizes = SIZES[size] ? SIZES[size] : {}

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      style={style}
      {...sizes}
      {...props}
    />
  )
}
