/*
  Maintains a tree of ontology terms, which is patched together as necessary from fragments downloaded from
  static files. There are just two trees of interest (properties & values) which are exposed as singletons.

  Note that all of the URIs in this file are the abbreviated versions, e.g. "bao:BAO_1234567", which is the
  form that is also used by the static lookup files.
*/

import axios from 'axios';
import { ONTOLOGY_URL_BASE, ONTOLOGY_URL_SFX, collapsePrefix } from './utils';

export interface Branch {
  parent: Branch;
  uri: string;
  children: Branch[];
  group: number; // subsection where content is found

  // auxiliary content which is loaded separately; null = unloaded
  label?: string;
  description?: string;
  altLabels?: string[];
  externalURLs?: string[];
}

export class OntologyTree {
  public static props: OntologyTree; // eslint-disable-line  no-use-before-define
  public static values: OntologyTree; // eslint-disable-line  no-use-before-define

  private roots: Branch[] = null;
  private mapBranch = new Map<string, Branch>(); // uri-to-branch

  constructor(private pfx: string) {
  }

  // make sure the basic information is loaded; all of the other async methods will call this first, but can
  // be called explicitly because it does involve a potentially nontrivial pause
  public async init(): Promise<void> {
    if (this.roots) return;

    const url = ONTOLOGY_URL_BASE + this.pfx + 'hierarchy.txt' + ONTOLOGY_URL_SFX;
    const response = await axios.get<string>(url);
    if (!response.data) throw new Error('Failed to load ' + url);

    this.roots = [];
    const stack: Branch[] = [];
    for (const line of response.data.split('\n')) {
      if (!line) continue;
      let depth = 0;
      while (line[depth] == '-') depth++;
      const eq = line.lastIndexOf('=');
      const uri = line.substring(depth, eq);
      const group = parseInt(line.substring(eq + 1));

      const branch: Branch = {
        parent: null,
        uri,
        children: [],
        group,
      };

      if (depth > 0) {
        branch.parent = stack[depth - 1];
        branch.parent.children.push(branch);
      } else {
        this.roots.push(branch);
      }
      stack[depth] = branch;

      this.mapBranch.set(branch.uri, branch);
    }
  }

  // make sure the roots Re loaded, then return them
  public async getRoots(): Promise<Branch[]> {
    await this.init();
    return this.roots;
  }

  // obtains a branch from the hierarchy; if requested, will fill in label/details which may involve a separate loading operation; note that label/details
  // are only guaranteed for the node itself, not its children, unless the deepLoad parameter is set
  public async getBranch(uri: string, needLabel = true, needDetails = false, deepLoad = false): Promise<Branch> {
    if (!uri) return null;
    const branch = this.mapBranch.get(collapsePrefix(uri));
    if (!branch) return;

    if (needLabel && branch.label === undefined) await this.loadLabels(branch.group);
    if (needDetails && branch.description === undefined) await this.loadDetails(branch.group);

    if (deepLoad) {
      const needGroupLabel = new Set<number>(), needGroupDetails = new Set<number>();
      const scan = (look: Branch) => {
        if (needLabel && look.label === undefined) needGroupLabel.add(look.group);
        if (needDetails && look.description === undefined) needGroupDetails.add(look.group);
        for (const child of look.children) scan(child);
      };
      scan(branch);
      for (const group of needGroupLabel) await this.loadLabels(group);
      for (const group of needGroupDetails) await this.loadDetails(group);
    }

    return branch;
  }

  // returns what we know about the branch: null = not loaded or nonexistent; value content may not be available
  public cachedRoots(): Branch[] {
    return this.roots;
  }

  public cachedBranch(uri: string): Branch {
    if (!uri) return null;
    return this.mapBranch.get(collapsePrefix(uri));
  }

  public cachedLabel(uri: string): string {
    const branch = this.cachedBranch(uri);
    return branch ? branch.label : null;
  }

  // loads a chunk of labels and fills in all of the branches used
  private async loadLabels(group: number): Promise<void> {
    const numstr = group.toString();
    const url = ONTOLOGY_URL_BASE + this.pfx + 'label' + '0'.repeat(3 - numstr.length) + numstr + '.txt' + ONTOLOGY_URL_SFX;
    const response = await axios.get<string>(url);
    if (!response.data) throw new Error('Failed to load ' + url);

    for (const line of response.data.split('\n')) {
      if (!line) continue;
      const spc = line.indexOf(' ');
      const uri = line.substring(0, spc), label = line.substring(spc + 1);
      this.mapBranch.get(uri).label = label;
    }
  }

  // loads a chunk of details
  private async loadDetails(group: number): Promise<void> {
    const numstr = group.toString();
    const url = ONTOLOGY_URL_BASE + this.pfx + 'detail' + '0'.repeat(3 - numstr.length) + numstr + '.txt' + ONTOLOGY_URL_SFX;
    const response = await axios.get<string>(url);
    if (!response.data) throw new Error('Failed to load ' + url);

    for (const branch of Array.from(this.mapBranch.values())) {
      if (branch.group == group) {
        branch.description = '';
        branch.altLabels = [];
        branch.externalURLs = [];
      }
    }

    let branch: Branch = null;
    for (const line of response.data.split('\n')) {
      if (!line) continue;
      if (line.startsWith('  D ')) {
        branch.description = JSON.parse(line.substring(4));
      } else if (line.startsWith('  A ')) {
        if (!branch.altLabels) branch.altLabels = [];
        branch.altLabels.push(line.substring(4));
      } else if (line.startsWith('  U ')) {
        if (!branch.externalURLs) branch.externalURLs = [];
        branch.externalURLs.push(line.substring(4));
      } else branch = this.mapBranch.get(line);
    }
  }

  public static debugBranch(branch: Branch, depth = 0) {
    console.log('- '.repeat(depth) + '<' + branch.uri + '> ' + (branch.label || '?'));
    for (const child of branch.children) this.debugBranch(child, depth + 1);
  }
}

OntologyTree.props = new OntologyTree('prop_');
OntologyTree.values = new OntologyTree('value_');
