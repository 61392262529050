import askForConfirmation from '../components/ConfirmationDialog';

export class ModalUtils {
  static async showModal(
    contents: string | JSX.Element,
    options: {
      title?: string;
      icon?: JSX.Element;
      okLabel?: string;
      cancelLabel?: string;
      noCancelOption?: boolean;
    } = {},
  ): Promise<boolean> {
    return askForConfirmation({
      message: contents,
      ...options,
      terminology: {
        YES: options.okLabel,
        NO: options.cancelLabel,
      },
    });
  }

  static async confirmDelete(resourceName: string) {
    return ModalUtils.showModal(
      `Are you sure you want to delete ${resourceName}?`,
      {
        okLabel: 'Yes, Delete',
        cancelLabel: 'No',
      },
    );
  }

  static async confirmDepleteSample(
    sampleName: string,
    depleting = true,
  ) {
    return ModalUtils.showModal(
      `Are you sure you want to ${
        depleting ? 'deplete' : 'restore'
      } the sample ${sampleName}?`,
      {
        okLabel: `Yes, ${depleting ? 'Deplete' : 'Restore'} Sample`,
        cancelLabel: 'No',
      },
    );
  }
}
