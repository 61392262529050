import { cancelBroadcastError } from '@/ErrorReporting/subscribe';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { term } from '@/shared/utils/stringUtils';
import {
  extractParamsFromUrlPattern,
  vaultIdFromUrl,
} from '@/stores/routerStore';
import axios, { Method } from 'axios';
import { toJS } from 'mobx';
import {
  EditInventoryEvent,
  EditSample,
  InventoryEvent,
  InventorySampleResults,
} from './types';

type InventorySampleURLParams = {
  sample: Pick<EditSample, 'id'>;
  moleculeId: number;
};

export class InventorySampleService {
  public static getInventoryFieldDefinitions(vaultId = vaultIdFromUrl()) {
    const url = `/vaults/${vaultId}/inventory_field_definitions.json`;
    return axios.get<{
      inventory_sample_field_definitions: FieldDefinition[];
      inventory_event_field_definitions: FieldDefinition[];
    }>(url);
  }

  public static openMoleculeLinkInNewTab(moleculeId: number) {
    const vaultId = vaultIdFromUrl();
    const url = `/vaults/${vaultId}/molecules/${moleculeId}`;
    window.open(url, '_blank').focus();
  }

  public static getSamples(inVaultId?: number, inMoleculeId?: number) {
    const { pathname } = new URL(window.location.href);
    const params = extractParamsFromUrlPattern<'vaultId' | 'moleculeId'>(
      pathname,
      '/vaults/:vaultId/molecules/:moleculeId',
    );
    const vaultId = inVaultId ?? params?.vaultId;
    const moleculeId = inMoleculeId ?? params?.moleculeId;
    if (!vaultId || !moleculeId) {
      return; // undefined
    }

    const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples.json`;
    return axios.get<InventorySampleResults>(url);
  }

  public static async handleDeleteSample({
    sample,
    moleculeId,
    onAfterDelete,
  }: InventorySampleURLParams & {
    onAfterDelete: () => void;
    sample: EditSample;
  }) {
    if (
      await ModalUtils.confirmDelete(`${term('sample', true)} "${sample.name}"`)
    ) {
      const url = InventorySampleService.sampleURL({
        moleculeId,
        sample,
      });
      await axios.delete(url);

      onAfterDelete();
    }
  }

  public static async handleDeleteEvent({
    event,
    onAfterDelete,
    onGenericError,
    ...urlParams
  }: InventorySampleURLParams & {
    event: EditInventoryEvent;
    onAfterDelete: () => void;
    onGenericError: (error: any) => Promise<void>;
  }) {
    if (await ModalUtils.confirmDelete(`this ${term('event', true)}`)) {
      const url = InventorySampleService.sampleURL(urlParams);
      const method: Method = 'put';
      const formData = new FormData();
      formData.append(
        'inventory_sample[inventory_events_attributes][0][id]',
        `${event.id}`,
      );

      formData.append(
        'inventory_sample[inventory_events_attributes][0][_destroy]',
        '1',
      );
      try {
        await axios({
          url,
          method,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        onAfterDelete();
      } catch (error) {
        onGenericError(error);
      }
    }
  }

  public static async handleSubmitEditSample({
    sample,
    moleculeId,

    onSubmitSuccess,
    on409Error,
    onGenericError,
  }: InventorySampleURLParams & {
    onSubmitSuccess: () => void;
    on409Error: (sample: EditSample) => Promise<void>;
    onGenericError: (error: any) => Promise<void>;
    sample: EditSample;
  }) {
    const url = InventorySampleService.sampleURL({
      moleculeId,
      sample,
    });

    const formData = InventorySampleService.buildSubmitSampleFormData(sample);

    const method = 'put';
    try {
      await axios({
        url,
        method,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      onSubmitSuccess();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          cancelBroadcastError(error);
          on409Error(sample);
          return;
        }
      }
      onGenericError(error);
    }
  }

  public static async handleSubmitEditEvent({
    event,
    moleculeId,

    onSubmitSuccess,
    on409Error,
    onGenericError,
  }: {
    event: EditInventoryEvent;
    moleculeId: number;

    onSubmitSuccess: () => void;
    on409Error: (event: EditInventoryEvent) => Promise<void>;
    onGenericError: (error: any) => Promise<void>;
  }) {
    const url = InventorySampleService.sampleURL({
      moleculeId,
      sample: {
        id: event.inventory_sample_id,
      },
    });
    const formData = new FormData();
    const skipIds = [];

    InventorySampleService.addEventFieldsToFormData(
      event,
      formData,
      skipIds,
      'inventory_sample[inventory_events_attributes][0]',
    );

    const method = 'put';

    try {
      await axios({
        url,
        method,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      onSubmitSuccess();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          cancelBroadcastError(error);
          on409Error(event);
          return;
        }
      }
      onGenericError(error);
    }
  }

  private static sampleURL({ moleculeId, sample }: InventorySampleURLParams) {
    const vaultId = vaultIdFromUrl();

    return `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${sample.id}`;
  }

  private static buildSubmitSampleFormData(sample: EditSample) {
    const formData = new FormData();
    const { inventory_sample_fields = {} } = toJS(sample);

    ['id', 'name', 'units', 'batch_id', 'depleted'].forEach((key) => {
      if (sample[key] !== undefined) {
        formData.append(`inventory_sample[${key}]`, sample[key]);
      }
    });
    keysAsNumbers(inventory_sample_fields).forEach((fieldId, i) => {
      formData.append(
        `inventory_sample[inventory_sample_fields_attributes][${i}][field_definition_id]`,
        '' + fieldId,
      );
      let fieldValue = inventory_sample_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object' && fieldValue.uploaded_file_id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldFormData = (fieldValue as any).formData;
        fieldValue = fieldValue.uploaded_file_id;
        if (fieldFormData) {
          for (const pair of fieldFormData.entries()) {
            if (pair[0] === 'qqfile') {
              formData.append(pair[0], pair[1]);
            }
          }
        }
      }

      formData.append(
        `inventory_sample[inventory_sample_fields_attributes][${i}][value]`,
        '' + fieldValue,
      );
    });
    // when creating a new sample, add the inventory event fields as well
    const skipIds = [];
    const event = sample.inventory_events[0];

    if (event) {
      InventorySampleService.addEventFieldsToFormData(
        event,
        formData,
        skipIds,
        'inventory_sample[inventory_events_attributes][0]',
      );
    }
    return formData;
  }

  private static addEventFieldsToFormData = (
    event: Partial<InventoryEvent>,
    formData: FormData,
    skipIds: Array<number> = [],
    prefix = 'inventory_event',
  ) => {
    const data = { inventory_event: { ...toJS(event) } };

    const inventory_event_fields =
      data.inventory_event?.inventory_event_fields ?? {};

    const keys = keysAsNumbers(inventory_event_fields).filter(
      (key) => !skipIds.includes(key),
    );

    if (event.id) {
      formData.append(`${prefix}[id]`, '' + event.id);
    }
    for (let i = 0; i < keys.length; i++) {
      const fieldId = keys[i];
      formData.append(
        `${prefix}[inventory_event_fields_attributes][${i}][field_definition_id]`,
        '' + fieldId,
      );
      let fieldValue = inventory_event_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object') {
        if (fieldValue.uploaded_file_id) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldFormData = (fieldValue as any).formData;
          fieldValue = fieldValue.uploaded_file_id;
          if (fieldFormData) {
            for (const pair of fieldFormData.entries()) {
              if (pair[0] === 'qqfile') {
                formData.append(pair[0], pair[1]);
              }
            }
          }
        } else if (
          fieldValue.id &&
          fieldValue.value &&
          fieldValue.position !== undefined
        ) {
          fieldValue = `${fieldValue.id},${fieldValue.position}`; // location
        }
      }
      formData.append(
        `${prefix}[inventory_event_fields_attributes][${i}][value]`,
        '' + fieldValue,
      );
    }
    if (!keys.length) {
      formData.append(`${prefix}[inventory_event_fields_attributes][0]`, '');
    }
  };
}
