import {
  CustomNucleotidesSchema,
  EditCustomNucleotideSchema,
} from './types';

export class CustomNucleotidesUtils {
  static convertToEditCustomNucleotide(customNucleotide: CustomNucleotidesSchema): EditCustomNucleotideSchema {
    return {
      ...customNucleotide,
    };
  }
}
