import { OntologyTree } from '@/Annotator/data/OntologyTree';
import { Schema } from '@/Annotator/data/Schema';
import { FormDefinition, FormLayout, FormLayoutType, FormSection } from '@/Annotator/data/forms';
import { OntologyTemplate, TemplateGroup } from '@/Annotator/data/templates';
import { keyPropGroup } from '@/Annotator/data/utils';
import { RunData } from '@/RunData/types';
import { ColumnDef, ColumnSortDef } from '@/components';
import { compareCaseInsensitive, term } from '@/shared/utils/stringUtils';
import { RootStore } from '@/stores/rootStore';
import { AnyObject } from '@/types';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

type RunDataResults = {
  runs: Array<RunData>;
  run_field_names: string[];
}

export class RunDataStore {
  disposers: Array<() => void> = [];

  inited = false;

  protocolForm: FormDefinition | null = null;
  allRunFieldNames: string[] = [];
  runs: Array<RunData> = [];

  schema: Schema;
  private updateBump = 0;

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!this.inited) {
      this.inited = true;
    }
  }

  get vaultId() {
    const { vaultId } = this.root.routerStore.extractFromPattern('/vaults/:vaultId');
    return vaultId as number;
  }

  get protocolId() {
    const { protocolId } = this.root.routerStore.extractFromPattern('/vaults/:vaultId/protocols/:protocolId');
    return protocolId as number;
  }

  get rows() {
    const { vaultId } = this;

    const addPrefixToRunFields = (run_fields: AnyObject) => {
      return Object.keys(run_fields).reduce((result, key) => {
        result['run_fields.' + key] = run_fields[key];
        return result;
      }, {});
    };

    const needLookupValues = new Set<string>();

    const unpackOntologyAnnotations = (annotations: { prop_uri: string, group_nest: string[], value_uri: string, value_label: string}[]): Record<string, string> => {
      const results: Record<string, string> = {};
      const append = (key: string, label: string) => {
        const value = results[key];
        results[key] = value == null ? label : `${value}, ${label}`;
      };

      for (const annot of (annotations ?? [])) {
        const propURI = annot.prop_uri, groupNest = annot.group_nest, valueURI = annot.value_uri, valueLabel = annot.value_label;
        const key = keyPropGroup(propURI, groupNest);
        if (valueLabel) {
          append(key, valueLabel);
        } else if (valueURI && this.schema) {
          const label = this.schema.labelForURI(propURI, groupNest, valueURI) || OntologyTree.values.cachedLabel(valueURI);
          if (label) {
            append(key, label);
          } else {
            needLookupValues.add(valueURI);
          }
        }
      }

      return results;
    };

    const results = this.runs
      .map(run => ({
        ...run,
        ...addPrefixToRunFields(run.run_fields ?? {}),
        ...unpackOntologyAnnotations(run.ontology_annotations),
        run_link: `/vaults/${vaultId}/runs/${run.id}`,
        molecule_link: null,
      }));

    if (needLookupValues.size > 0) {
      (async () => {
        this.fillOntologyCache(Array.from(needLookupValues));
      })();
    }

    const { id, direction } = this.sortedColumn;

    // sort by column
    if (id === 'molecules') {
      results.sort((row1, row2) => row1.num_compounds - row2.num_compounds);
      if (direction === 'desc') {
        results.reverse();
      }
    } else if (id === 'plates') {
      results.sort((row1, row2) => row1.num_plates - row2.num_plates);
      if (direction === 'desc') {
        results.reverse();
      }
    } else {
      results.sort((row1, row2) => {
        let val1 = row1[id];
        let val2 = row2[id];
        if (typeof val1 === 'object') {
          val1 = val1.file_name ?? val1.value;
        }
        if (typeof val2 === 'object') {
          val2 = val2.file_name ?? val2.value;
        }
        return compareCaseInsensitive(val1, val2, direction);
      });
    }

    // then by project
    results.sort((row1, row2) => compareCaseInsensitive(row1.project?.name, row2.project?.name));

    return results;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  setProtocolForm(form: FormDefinition, schemaPrefix: string, templateList: OntologyTemplate[]) {
    this.protocolForm = form;
    schemaPrefix = schemaPrefix ?? form?.run_form?.schemaPrefix;

    if (schemaPrefix) {
      const template = templateList.find((look) => look.schemaPrefix == schemaPrefix);
      if (template) {
        this.schema = new Schema(template);
      }
    }
  }

  loadRunResults() {
    const { routerStore } = this.root;
    const { vaultId, protocolId } = routerStore.extractFromPattern('/vaults/:vaultId/protocols/:protocolId',
    ) as { vaultId?: number, protocolId?: number};

    const url = `/vaults/${vaultId}/protocols/${protocolId}/runs.json`;
    axios.get<RunDataResults>(url)
      .then(result => {
        this.setRunResults(result.data.runs, result.data.run_field_names.sort());
      });
  }

  setRunResults(rows: Array<RunData>, allRunFieldNames: string[]) {
    this.runs = rows;
    this.allRunFieldNames = allRunFieldNames;
  }

  get runFieldNames() {
    const runFieldsForm = this.protocolForm?.run_form;
    if (runFieldsForm) {
      // if there's a custom form that defines the run fields, use only the fields on the form
      const results: Array<string> = [];
      const addFields = (element: FormSection | FormLayout) => {
        if ('contents' in element && element.contents) {
          element.contents.forEach(child => addFields(child));

          if ('layoutType' in element && element.layoutType === FormLayoutType.Row) {
            let label = '';
            element.contents.forEach(element => {
              if (element.label) {
                label = element.label;
              } else if (element.fieldID) {
                results.push(label);
              }
            });
          }
        }
      };
      runFieldsForm.sections.forEach(addFields);
      return results.sort();
    }
    return this.allRunFieldNames;
  }

  get visibleColumnIds() {
    const { protocolId, root: { protocolsStore: { perProtocolPreferences } } } = this;
    return Array.from(new Set(perProtocolPreferences?.[protocolId]?.visibleColumnIds ?? this.defaultColumnIds));
  }

  setVisibleColumns(value: Array<string>) {
    const { protocolId, root: { protocolsStore } } = this;
    protocolsStore.setVisibleColumnIdsForProtocolRunFields(protocolId, value);
  }

  get sortedColumn() {
    const { protocolId, root: { protocolsStore } } = this;
    return protocolsStore.perProtocolPreferences?.[protocolId]?.sortedColumn ?? { id: 'run_date', direction: 'desc' };
  }

  setSortBy(value: ColumnSortDef) {
    const { protocolId, root: { protocolsStore } } = this;
    protocolsStore.setSortColumnProtocolRunFields(protocolId, value);
  }

  get availableColumns(): ColumnDef[] {
    const hardCodedColumns = [
      {
        id: 'run_date',
        label: `${term('run', true)} Date`,
      },
      {
        id: 'molecules',
        label: term('molecule.other', true),
      },
      {
        id: 'plates',
        label: 'Plates',
      },
    ];

    const hardCodedLabels = hardCodedColumns.map(column => column.label);
    return [
      ...hardCodedColumns,
      ...this.runFieldNames
        .filter(name => !hardCodedLabels.includes(name))
        .map(name => ({ id: `run_fields.${name}`, label: name })),
      ...this.ontologyDefinitionsForColumns,
    ];
  }

  private get ontologyDefinitionsForColumns(): ColumnDef[] {
    const form = this.protocolForm?.run_form;
    if (!this.schema) return [];

    const results: ColumnDef[] = [];

    const observedAssns = new Set<string>();
    for (const run of this.runs) {
      for (const annot of (run.ontology_annotations ?? [])) {
        observedAssns.add(keyPropGroup(annot.prop_uri, annot.group_nest));
      }
    }

    const scrapeFormTerms = (contents: FormLayout[]): void => {
      for (const layout of contents ?? []) {
        if (layout.ontologyAssn) {
          const propURI = layout.ontologyAssn[0], groupNest = layout.ontologyAssn.slice(1);
          const assn = this.schema.findAssignment(propURI, groupNest);
          if (assn) {
            const id = keyPropGroup(propURI, groupNest);
            if (observedAssns.has(id)) {
              results.push({ id, label: assn.name });
            }
          }
        }
        scrapeFormTerms(layout.contents);
      }
    };

    const scrapeTemplateGroup = (group: TemplateGroup, parentNest: string[]) => {
      const groupNest = [group.groupURI, ...parentNest].filter((uri) => !!uri);
      for (const assn of group.assignments) {
        const id = keyPropGroup(assn.propURI, groupNest);
        if (observedAssns.has(id)) {
          results.push({ id, label: assn.name });
        }
      }
      for (const subGroup of (group.subGroups ?? [])) {
        scrapeTemplateGroup(subGroup, groupNest);
      }
    };

    if (form) {
      for (const section of form.sections) scrapeFormTerms(section.contents);
    } else {
      scrapeTemplateGroup(this.schema.template.root, []);
    }

    return results;
  }

  get defaultColumnIds() {
    return [
      'run_date',
      ...this.runFieldNames.map(name => `run_fields.${name}`),
      'molecules',
      'plates',
    ];
  }

  get displayedColumns() {
    const columnMap = this.availableColumns.reduce((result, column) => {
      result[column.id] = column;
      return result;
    }, {});

    return this.visibleColumnIds
      .map(id => columnMap[id])
      .filter(def => !!def)
      .map(column => {
        if (column?.id === this.sortedColumn.id) {
          return { ...column, direction: this.sortedColumn.direction };
        }
        return column;
      });
  }

  private async fillOntologyCache(valueURIList: string[]): Promise<void> {
    for (const uri of valueURIList) {
      OntologyTree.values.getBranch(uri, true, false, false);
    }
    setTimeout(() => this.incrementBumpState(), 0);
  }

  private incrementBumpState(): void {
    this.updateBump++;
  }
}
