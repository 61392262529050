import { RootStore } from '@/stores/rootStore';
import _ from 'lodash';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { accountsService } from './accountsServices';
import { VaultsAndProjectsStore } from './Projects/stores/vaultsAndProjectsStore';
import { TeamsStore } from './Teams/stores/teamsStore';
import {
  AccountSchema,
  TeamSchema,
  UserSchema,
  VaultWithProjectsSchema,
  ProjectSchema,
} from './types';
import { UsersStore } from './Users/stores/usersStore';
import { CustomNucleotidesStore } from './CustomNucleotides/stores/customNucleotidesStore';
import { CustomAminoAcidsStore } from './CustomAminoAcids/stores/customAminoAcidsStore';

export interface AccountDataLookup {
  accountsMap: { [id: number]: AccountSchema };
  usersMap: { [id: number]: UserSchema };
  vaultsMap: { [id: number]: VaultWithProjectsSchema };
  teamsMap: { [id: number]: TeamSchema };
  projectsIdToVaultIdMap: { [id: number]: number };
  projectsMap: { [id: number]: ProjectSchema };
}

export class AccountsStore implements AccountDataLookup {
  root: RootStore;

  inited = false;
  loading = 0;

  usersStore: UsersStore;
  customNucleotidesStore: CustomNucleotidesStore;
  customAminoAcidsStore: CustomAminoAcidsStore;
  vaultsStore: VaultsAndProjectsStore;
  teamsStore: TeamsStore;
  vaultsAndProjectsStore: VaultsAndProjectsStore;

  accounts: Array<AccountSchema> = [];
  disposers: Array<() => void> = [];
  accountForVaultId = 0;
  lastLoadedForAccountId = 0;

  constructor(root: RootStore) {
    this.root = root;
    this.usersStore = new UsersStore(root);
    this.teamsStore = new TeamsStore(root);
    this.vaultsAndProjectsStore = new VaultsAndProjectsStore(root);
    this.customNucleotidesStore = new CustomNucleotidesStore(root);
    this.customAminoAcidsStore = new CustomAminoAcidsStore(root);

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!this.inited) {
      this.inited = true;

      // react to url changes by updating model data and loading account data
      this.disposers.push(
        reaction(
          () => this.accountsUrlParams,
          (accountsUrlParams) => {
            if (accountsUrlParams && this.accounts.length === 0) {
              this.loadAccounts();
            }
          },
          { fireImmediately: true },
        ),
      );
    }
    this.usersStore.init();
    this.customNucleotidesStore.init();
    this.customAminoAcidsStore.init();
    this.teamsStore.init();
    this.vaultsAndProjectsStore.init();
  }

  // computed maps for quick lookup
  get accountsMap(): { [id: number]: AccountSchema } {
    return _.keyBy(this.accounts, 'id');
  }

  get usersMap() {
    return this.usersStore.usersMap;
  }

  get vaultsMap() {
    return this.vaultsAndProjectsStore.vaultsMap;
  }

  get projectsIdToVaultIdMap() {
    return this.vaultsAndProjectsStore.projectsIdToVaultIdMap;
  }

  get teamsMap() {
    return this.teamsStore.teamsMap;
  }

  get teamDetailsMap() {
    return this.teamsStore.teamDetailsMap;
  }

  get projectsMap() {
    return this.vaultsAndProjectsStore.projectsMap;
  }

  get projectsForSelectedVaultMap() {
    return this.vaultsAndProjectsStore.projectsForSelectedVaultMap;
  }

  /**
   * null if not on Accounts page, otherwise data extracted from url
   */
  get accountsUrlParams() {
    const { routerStore } = this.root;
    const protocolsParams = routerStore.extractFromPattern(
      '/vaults/:vaultId/accounts/:accountId',
    );
    if (protocolsParams) {
      return {
        vaultId: protocolsParams.vaultId as number | undefined,
        accountId: protocolsParams.accountId as number | undefined,
      };
    }
    return null;
  }

  get vaultId() {
    return this.accountsUrlParams?.vaultId;
  }

  get accountId() {
    return this.accountsUrlParams?.accountId ?? this.accountForVaultId;
  }

  get currentAccount() {
    return (
      this.accounts &&
      this.accounts.find((account) => '' + account.id === '' + this.accountId)
    );
  }

  get canManageTeams() {
    return true;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  /**
   * TODO - we should be smarter about integrating API results into our data models from PUT/POST/DELETE results.
   * But let's be quick and dirty for now.
   *
   * Default is to reload everything.
   */
  async reloadData(options?: {
    reloadUsers?: boolean /* = true */,
    reloadProjects?: boolean /* = true */,
    reloadTeams?: boolean /* = true */,
    reloadCustomNucleotides?: boolean /* = true */,
    reloadCustomAminoAcids?: boolean /* = true */,
  }) {
    // default if nothing is passed is to reload everything
    const { reloadUsers, reloadProjects, reloadTeams, reloadCustomNucleotides, reloadCustomAminoAcids } =
      { reloadUsers: true, reloadProjects: true, reloadTeams: true, reloadCustomNucleotides: true, reloadCustomAminoAcids: true, ...options };

    if (reloadUsers || reloadProjects || reloadTeams || reloadCustomNucleotides || reloadCustomAminoAcids) {
      if (reloadUsers) {
        this.root.accountsStore.usersStore.loadUsers();
        this.root.accountsStore.vaultsAndProjectsStore.refreshVaultUsers();
      }
      if (reloadProjects) {
        this.root.accountsStore.vaultsAndProjectsStore.loadProjectsForVault();
      }
      if (reloadTeams) {
        this.root.accountsStore.teamsStore.loadTeams();
      }
      if (reloadCustomNucleotides) {
        this.root.accountsStore.customNucleotidesStore.loadCustomNucleotides();
      }
      if (reloadCustomAminoAcids) {
        this.root.accountsStore.customAminoAcidsStore.loadCustomAminoAcids();
      }
    }
  }

  private async loadAccounts() {
    try {
      this.incrementLoading();
      const response = await accountsService.getAccounts();
      runInAction(() => {
        this.accounts = response.data;
      });
    } finally {
      this.decrementLoading();
    }
  }
}
