export enum StructureFormat {
  MRV = 'mrv', // Marvin's derivative of the baseline CML (Chemical Markup Language) format, molecules & reactions
  KET = 'ket', // Ketcher format (JSON-based)
  MOL = 'mol', // Molfile, could be V2000 or V3000
  MOLV3000 = 'molv3000', // Molfile V3000 (whether it needs to be or not)
  SMILES = 'smiles', // garden variety SMILES line notation
  CXSMILES = 'cxsmiles', // normal SMILES with the option of ChemAxon extensions
}

export interface StructureEditor {
  getStructure(format?: StructureFormat): Promise<string>; // get current structure from editor
  addStructure(structure: string): void; // add structure to editor
}
