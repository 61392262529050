import { FormDefinition, FormLayoutType } from '@/Annotator/data/forms';

export const blankFormTemplate: FormDefinition = {
  protocol_form: {
    sections: [{
      name: '',
      contents: [
        {
          'layoutType': FormLayoutType.Table,
          'context': 0,
          'contents': [],
        },
      ],
    }],
  },

  readout_form: {
    sections: [{
      name: '',
      contents: [
        {
          'layoutType': FormLayoutType.Table,
          'context': 0,
          'contents': [],
        },
      ],
    }],
  },

  run_form: {
    sections: [{
      name: '',
      contents: [
        {
          'layoutType': FormLayoutType.Table,
          'context': 0,
          'contents': [],
        },
      ],
    }],
  },
};
