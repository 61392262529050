import { makeAutoObservable } from 'mobx';
import { FormLayout, FormLayoutType } from '@/Annotator/data/forms';
import { RootStore } from '@/stores/rootStore';
import { EditFormUtils } from '../utils/editFormUtils';
import { FormLayoutForEditing } from '../types';
import { deepClone } from '@/Annotator/data/utils';

export type EditFormDragState = {
  contentsContainer?: HTMLElement | null;
  isDragging?: boolean;
  isMouseDown?: boolean;
  currentDraggableId?: string | null;
  formClientPosition?: { x: number; y: number };
  draggableBounds?: DOMRect | null;
  formBounds?: DOMRect | null;
  innerDragHtml?: { __html: string } | null;
  mouseDownEvent?: React.MouseEvent<HTMLElement> | null;
  mouseMoveEvent?: React.MouseEvent<HTMLElement> | null;
  initialScrollPosition?: { x: number; y: number };
}

const rowHeight = 54; // for now, just hard code this

export class EditFormDragStore {
  dragState: EditFormDragState = {};

  lastDragRows: Array<FormLayoutForEditing> = [];

  currentTransitions: {[id: string]: { from: DOMRect, to: DOMRect } } = {};

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get draggedItemIsInDisplayedRows() {
    const { currentDraggableId } = this.dragState;
    const { displayedRows } = this.root.formDefinitionsStore.editFormDefinitionStore;

    return displayedRows.some(row => (row.contents ?? []).some(cell => cell?.layoutID === currentDraggableId));
  }

  // drag state and functions
  setDragState(value: EditFormDragState) {
    if (value.isDragging === false && this.dragState.isDragging === true) {
      this.performDrop();
    }

    Object.assign(this.dragState, value);

    const {
      isMouseDown, isDragging, mouseMoveEvent, mouseDownEvent, formBounds, contentsContainer,
    } = this.dragState;

    if (isMouseDown && value.mouseMoveEvent) {
      if (!isDragging) {
        const xd = mouseMoveEvent.clientX - mouseDownEvent.clientX;
        const yd = mouseMoveEvent.clientY - mouseDownEvent.clientY;
        if ((xd * xd + yd * yd) > 20) {
          this.dragState.isDragging = true;
          this.lastDragRows = this.root.formDefinitionsStore.editFormDefinitionStore.displayedRows;
        }
      }

      const formX = mouseMoveEvent.clientX - formBounds.left + contentsContainer.scrollLeft - 10;
      const formY = mouseMoveEvent.clientY - formBounds.top + contentsContainer.scrollTop - 10;
      this.dragState.formClientPosition = { x: formX, y: formY };
    }
  }

  adjustDisplayedRowsForDrag(inRows: Array<FormLayoutForEditing>): Array<FormLayout> {
    const { isDragging, currentDraggableId, formClientPosition, formBounds } = this.dragState;

    if (!isDragging || !formClientPosition) {
      return inRows;
    }

    const { formDefinitionsStore: { editFormDefinitionStore } } = this.root;

    // Remove the current draggable from the rows
    let rows = [...inRows];
    rows.forEach(row => {
      if (row.contents) {
        row.contents = row.contents.filter(cell => cell.layoutID !== currentDraggableId);
      }
    });

    // Remove any empty rows
    rows = rows.filter(row => row.contents?.length > 0);

    const beforeDrag = JSON.stringify(rows);

    const item = editFormDefinitionStore.formItemFromId(currentDraggableId) ??
      editFormDefinitionStore.sidebarItemFromId(currentDraggableId);

    if (item && formClientPosition.x >= 0 && formClientPosition.x < formBounds.width) {
      if (formClientPosition.y < 0) {
        // above all rows
        rows.unshift({
          layoutID: 'temp_empty_top_row',
          layoutType: FormLayoutType.Row,
          contents: [item],
        });
      } else if (formClientPosition.y > rowHeight * rows.length) {
        // below all rows
        rows.push({
          layoutID: 'temp_empty_bottom_row',
          layoutType: FormLayoutType.Row,
          contents: [item],
        });
      } else {
        const inRowPos = formClientPosition.y % rowHeight;
        const rowIndex = Math.floor(formClientPosition.y / rowHeight);

        if (inRowPos < rowHeight * 0.25) {
          // above a row
          rows.splice(rowIndex, 0, {
            layoutID: `temp_empty_row_${rowIndex}`,
            layoutType: FormLayoutType.Row,
            contents: [item],
          });
        } else if (inRowPos > rowHeight * 0.75) {
          // below a row
          rows.splice(rowIndex + 1, 0, {
            layoutID: `temp_empty_row_${rowIndex + 1}`,
            layoutType: FormLayoutType.Row,
            contents: [item],
          });
        } else if (rowIndex >= 0 && rowIndex < rows.length && rows[rowIndex].contents.length < 3) {
          // in a row
          const itemsInRow = 1 + rows[rowIndex].contents.length;
          const colWidth = formBounds.width / itemsInRow;
          const index = Math.max(0, Math.floor((formClientPosition.x + colWidth / 2) / colWidth));
          rows[rowIndex].contents.splice(index, 0, item);
        }
      }
    }

    if (beforeDrag === JSON.stringify(rows)) {
      return inRows;
    }

    return rows;
  }

  handleDoubleClick(event: React.MouseEvent<HTMLElement>, layoutID: string) {
    if (layoutID) {
      this.setDragState({
        isDragging: true,
        isMouseDown: true,
        currentDraggableId: layoutID,
        formClientPosition: { x: 0, y: Infinity },
        formBounds: new DOMRect(0, 0, 100, 100),
      });
      this.setDragState({
        isDragging: false,
        isMouseDown: false,
      });
    }
  }

  performDrop() {
    const {
      formDefinitionsStore: {
        editFormDefinitionStore,
        editFormDefinitionStore: { setCurrentFormRows, updateSchemaPrefixes, displayedRows },
      },
    } = this.root;

    let { currentDraggableId } = this.dragState;

    // if the current item was dragged outside the form, just return without calling setCurrentFormRows
    if (!this.draggedItemIsInDisplayedRows) {
      return;
    }

    let addedNewLabel = false;

    // change the id of any dropped label to a new id
    let newRows = deepClone(displayedRows);
    newRows = this.adjustDisplayedRowsForDrag(newRows);

    newRows.forEach(row => {
      if (row.layoutID.startsWith('temp_')) {
        row.layoutID = EditFormUtils.getUniqueLayoutId();
      }
      row.contents && row.contents.forEach((cell, index) => {
        if (cell.layoutID === EditFormUtils.dragLabelFromSidebarId) {
          if (currentDraggableId === cell.layoutID) {
            cell = deepClone(cell);
            row.contents[index] = cell;
            addedNewLabel = true;
            cell.layoutID = EditFormUtils.getUniqueLayoutId();
            cell.label = '';
            currentDraggableId = cell.layoutID;
          }
        }
      });
    });

    setCurrentFormRows(newRows);
    updateSchemaPrefixes();

    if (addedNewLabel) {
      const item = editFormDefinitionStore.formItemFromId(currentDraggableId);
      // open edit field dialog after dragging in text
      if (item && EditFormUtils.isLabel(item)) {
        editFormDefinitionStore.handleEditField(item);
      }
    }
  }

  get dragTransform() {
    const {
      isDragging, draggableBounds,
      mouseMoveEvent, mouseDownEvent,
      currentDraggableId,
    } = this.dragState;
    if (isDragging) {
      const { displayedRows } = this.root.formDefinitionsStore.editFormDefinitionStore;

      const draggedItemIsInDisplayedRows = displayedRows.some(row =>
        (row.contents ?? []).some(cell => cell.layoutID === currentDraggableId));
      const x = (draggableBounds?.x ?? 0) +
        (mouseMoveEvent.clientX - mouseDownEvent?.clientX ?? 0);
      const y = (draggableBounds?.y ?? 0) +
        (mouseMoveEvent.clientY - mouseDownEvent?.clientY ?? 0);

      return {
        width: draggableBounds?.width + 'px',
        height: draggableBounds?.height + 'px',
        transform: `translateX(${x}px) translateY(${y}px)`,
        display: draggedItemIsInDisplayedRows ? 'none' : 'block',
      };
    } else {
      return {};
    }
  }
}
