/* eslint-disable @typescript-eslint/no-unused-vars, no-jquery/no-find-collection, no-jquery/no-jquery-constructor, no-jquery/no-other-methods, no-jquery/no-text, no-jquery/no-trigger, no-jquery/no-val */

/**
 * This store scrapes the hidden protocol criteria UI from the DOM and produces JSON that MuiDDForm can render.
 * See the following document for all the gory details:
 * https://docs.google.com/document/d/14iGtJ9_uT2HslmwH4oWoeY99qeAK64LZB0PWrALMRkM/edit?usp=sharing
 */

import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { CDDElements } from '@/shared/components/CDDForm/cddElements';
import { RowColumnDef } from '@/shared/components/DDForm/types/rowColumnDef';
import { FieldDefinition } from '@/FieldDefinitions/types';
import _, { isArray } from 'lodash';
import { ElementDef } from '@/shared/components/DDForm/types/base/elementDef';
import { SelectMultipleDef } from '@/shared/components/DDForm/types/selectMultipleDef';
import { DDFormUtils } from '@/shared/components/DDForm/DDFormUtils';
import { FieldValueType } from '@/shared/components/DDForm/types';
import { AnyObject } from '@/types';

const {
  row,
  column,
  textInput,
  numberInput,
  select,
  typography,
} = layoutBuilder;

const regExpSearchUrl = /\/vaults\/[0-9]+\/searches\//gm;

export class ProtocolSearchCriteriaStore {
  inited = false;
  disposers: Array<() => void> = [];
  isObserving = false;

  layout = column({}, []);
  termIds = [];
  data = {};

  dataKeyToCriteriaInDOM: Record<string, Array<HTMLElement>> = {};

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
    window.addEventListener(
      'rjsPostProcessing',
      this.handleRjsPostProcessing,
    );
    this.disposers.push(() => {
      window.removeEventListener(
        'rjsPostProcessing',
        this.handleRjsPostProcessing,
      );
    });
  }

  handleRjsPostProcessing() {
    if (this.inited) {
      this.stopObserving();
      this.startObserving();
    }
  }

  // Create an observer instance linked to the callback function
  observer = new MutationObserver((mutationList) => {
    let allTextAreas = true;
    mutationList.forEach(mutation => {
      if ((mutation.target as HTMLElement).tagName !== 'TEXTAREA') {
        allTextAreas = false;
      }
    });
    if (!allTextAreas) {
      this.scrapeDataFromDOM();
    }
  });

  async init() {
    if (this.inited) {
      return;
    }
    const { protocolsStore } = this.root;
    if (!protocolsStore.fieldDefinitions.length) {
      await protocolsStore.loadFieldDefinitions();
    }
    this.inited = true;

    const onProtocolSearchPage = !!document.getElementById('protocol_criteria');
    if (onProtocolSearchPage && !this.isObserving) {
      this.startObserving();
    } else {
      if (!onProtocolSearchPage && this.isObserving) {
        this.isObserving = false;
        this.observer.disconnect();
      }
    }

    reaction(() => this.fieldDefinitions, (fieldDefinitions) => {
      if (this.isObserving && fieldDefinitions.length) {
        this.scrapeDataFromDOM(fieldDefinitions);
      }
    });
  }

  get fieldDefinitions() {
    const { vaultId } = this.root.routerStore.extractFromPattern('/vaults/:vaultId') ?? {};
    if (!vaultId) {
      return [];
    }
    const fieldDefinitionsForVaults = this.root.protocolsStore.fieldDefinitions;
    return fieldDefinitionsForVaults.find(def => def.id === vaultId)?.protocol_field_definitions ?? [];
  }

  stopObserving() {
    this.isObserving = false;
    this.observer.disconnect();
  }

  startObserving() {
    const protocolCriteriaElem = document.getElementById('protocol_criteria');
    if (!protocolCriteriaElem && this.root.routerStore.url.href.match(regExpSearchUrl)) {
      requestAnimationFrame(() => { this.startObserving(); });
      return;
    }
    if (!this.isObserving) {
      if (protocolCriteriaElem) {
        this.disposers.push(() => {
          this.stopObserving();
        });

        // Start observing the target node for configured mutations
        this.observer.observe(protocolCriteriaElem, {
          subtree: true,
          childList: true,
          attributes: true,
          attributeFilter: ['style'],
        });
        this.isObserving = true;
        this.scrapeDataFromDOM();
      }
    }
  }

  findAssociatedElem(path: string) {
    // some of the hidden form elements have duplicated ids. In that case, our path contains an index to
    // the criterion containing the form element we want to set. So extract the index and use it to
    // find the correct element.
    let elem;
    const disambiguatePos = path.indexOf('_disambiguate');
    if (disambiguatePos !== -1) {
      path = path.substring(0, disambiguatePos);
      const indexStart = path.lastIndexOf('_');
      const criterionIndex = parseInt(path.substring(indexStart + 1));
      path = path.substring(0, indexStart);
      elem = $('#protocol_criteria').find(`[id="${path}"]`).eq(criterionIndex)[0];
    } else {
      elem = $('#protocol_criteria').find(`[id="${path}"]:visible`)[0];
    }
    return elem;
  }

  findRowInHiddenForm(elem: HTMLElement) {
    let parent = elem.parentElement;
    while (parent !== null) {
      if (parent.parentElement.id === 'protocol_criteria') {
        break;
      }
      parent = parent.parentElement;
    }
    return parent;
  }

  scrapeMenuOptions(selectElem: HTMLSelectElement, flattenGroups = true) {
    const selectOptions = [];
    const addChildOption = (childElem: HTMLOptionElement | HTMLOptGroupElement) => {
      if (childElem.tagName === 'OPTION') {
        selectOptions.push({
          label: childElem.label,
          id: (childElem as HTMLOptionElement).value,
        });
      } else if (childElem.tagName === 'OPTGROUP') {
        const groupOption = {
          label: childElem.label,
          children: [],
        };
        const target = flattenGroups ? selectOptions : groupOption.children;
        for (let i = 0; i < childElem.children.length; i++) {
          const srcOption = (childElem.children[i] as HTMLOptionElement);
          target.push({
            label: srcOption.label,
            id: srcOption.value,
            group: childElem.label,
          });
        }
        if (!flattenGroups) {
          selectOptions.push(groupOption);
        }
      }
    };

    for (let i = 0; i < selectElem.children.length; i++) {
      addChildOption(selectElem.children[i] as (HTMLOptionElement | HTMLOptGroupElement));
    }
    return selectOptions;
  }

  getControlsFromLayout(row: RowColumnDef) {
    const result = [];
    const addChildren = (child) => {
      if (child.type === 'row' || child.type === 'column') {
        child.children.forEach(addChildren);
      } else {
        result.push(child);
      }
    };
    addChildren(row);
    return result;
  }

  /**
   * Given a layout and data, return an array of values for each control in the layout.
   * @param layout
   * @param data
   * @returns
   */
  getCriteriaValuesFromLayout(layout: RowColumnDef, data = this.data) {
    const result = [];
    this.getControlsFromLayout(layout).forEach((child) => {
      if (child.key) {
        let value = DDFormUtils.getValue(child.key, data, 'direct');
        if (child.type === 'multiselect' && !isArray(value)) {
          value = [value];
        }
        result.push(value);
      } else {
        result.push(null);
      }
    });

    return result;
  }

  /**
   * Try to combine the last two rows in the layout into a single row if they contain compatible multiselects.
   * @param layout
   * @param data
   * @returns
   */
  tryToCombinePreviousLastCriterionIntoMultiselect(layout: RowColumnDef, data: object) {
    if (layout.children.length < 3) {
      return;
    }
    const getRowSignature = (row: RowColumnDef) => {
      return JSON.stringify(this.getControlsFromLayout(row).map(x => _.omit(x, ['id', 'key', 'name'])));
    };

    // consider the last three rows added so far.
    const lastThreeRows = layout.children.slice(-3) as RowColumnDef[];

    // we will only continue if the middle row is an OR junction and the first and last rows have the same controls
    if (lastThreeRows[1].children[0].className !== 'protocol_readout_junction' || data[lastThreeRows[1].children[0].id] !== 'or' ||
      getRowSignature(lastThreeRows[0]) !== getRowSignature(lastThreeRows[2])) {
      return;
    }

    // inspect the values of the controls in the first and last rows to see if they can be combined
    const targetValues = this.getCriteriaValuesFromLayout(lastThreeRows[0], data);
    const sourceValues = this.getCriteriaValuesFromLayout(lastThreeRows[2], data);
    if (targetValues.length !== sourceValues.length) {
      return;
    }

    // find the one differing multiselect. Any other differences disqualify this as a candidate for combining
    let differingMultiselectIndex = -1;
    for (let i = 0; i < targetValues.length; i++) {
      const value1 = targetValues[i];
      const value2 = sourceValues[i];
      if (Array.isArray(value1)) {
        value1.sort();
      }
      if (Array.isArray(value2)) {
        value2.sort();
      }

      const differs = JSON.stringify(value1) !== JSON.stringify(value2);
      if (differs) {
        // the fields have different values
        if (Array.isArray(targetValues[i]) && Array.isArray(sourceValues[i])) {
          // both are multiselects
          if (differingMultiselectIndex === -1) {
            // if we haven't yet found a differing multiselect, use this one
            differingMultiselectIndex = i;
          } else {
            // if we have already found a differing multiselect, we cannot continue
            return;
          }
        } else {
          return;
        }
      }
    }

    if (differingMultiselectIndex === -1) {
      return;
    }

    const controlsTargetRow = this.getControlsFromLayout(lastThreeRows[0]);
    const controlsSourceRow = this.getControlsFromLayout(lastThreeRows[2]);
    const targetMultiselect: SelectMultipleDef = controlsTargetRow[differingMultiselectIndex];
    const sourceMultiselect: SelectMultipleDef = controlsSourceRow[differingMultiselectIndex];

    // we're combining controls from the source into the target, so combine the arrays of related DOM elements
    controlsSourceRow.forEach((control, i) => {
      const source = this.dataKeyToCriteriaInDOM[control.key];
      const dest = this.dataKeyToCriteriaInDOM[controlsTargetRow[i].key];
      if (dest && source) {
        dest.push(...source);
      }
    });

    if (targetMultiselect && sourceMultiselect) {
      data[targetMultiselect.id] = [
        ...(data[targetMultiselect.id] ?? []),
        ...(data[sourceMultiselect.id] ?? []),
      ];
      if (data[targetMultiselect.id].length + 1 === targetMultiselect.selectOptions.length &&
        targetMultiselect.selectOptions.some(option => ('' + option.id).trim() === '')) {
        data[targetMultiselect.id] = [''];
      }
      delete data[sourceMultiselect.id];
    }

    // remove the last two layout rows as they've been combined. https://www.youtube.com/watch?v=G_P5pC0RgSY&t=4s
    layout.children.pop();
    layout.children.pop();

    let result = 1;

    /**
    now that we combined the last two rows, we need to check if the previous row can be combined with the new last row.

    IN: v1 = a, v2 = x.    OUT: [ { v1 = a, v2 = x } ]
    IN: v1 = a, v2 = y.    OUT: [ { v1 = a, v2 = [ x, y ] } ]
    IN: v1 = b, v2 = x.    OUT: [ { v1 = a, v2 = [ x, y ] }, { v1 = b, v2 = x } ]
    IN: v1 = b, v2 = y.    Step #1, OUT: [ { v1 = a, v2 = [ x, y ] }, { v1 = b, v2 = y } ]
                           Step #2, OUT: [ { v1 = [ a, b ], v2 = [ x, y ] } ]
    */
    if (this.tryToCombinePreviousLastCriterionIntoMultiselect(layout, data)) {
      ++result;
    }
    return result;
  }

  removeMultipleSelectCloneCriteriaAfter(elem: HTMLElement) {
    while (elem.parentElement.id !== 'protocol_criteria') {
      elem = elem.parentElement;
    }
    const children = $('#protocol_criteria').children();
    const i = children.index(elem);
    if (i !== -1) {
      let i = children.index(elem);
      while (++i < children.length) {
        const nextElem = children[i];
        if (nextElem.dataset.combined === 'true') {
          nextElem.remove();
        } else {
          break;
        }
      }
    }
  }

  /**
   * Scrape the hidden form for protocol criteria and produce a JSON layout that MuiDDForm can render.
   * @param fieldDefinitions
   * @returns
   */
  scrapeDataFromDOM(fieldDefinitions: FieldDefinition[] = this.fieldDefinitions) {
    if (!this.inited) {
      return;
    }
    const layout = column({}, []);
    const termIds = [];
    const data = {};
    this.dataKeyToCriteriaInDOM = {};

    let fieldDefinition: FieldDefinition = null;
    let readoutFieldIsNumber = true;

    const duplicateIdSet = new Set<string>();
    const createLayoutForControl = (elem: HTMLElement) => {
      let key = elem.id;

      // ensure there are no duplicate ids. If this id is in use, we'll assign it a new id with an index
      // that will be used to find and set the hidden form control value.
      let i = 0;
      while (duplicateIdSet.has(key)) {
        key = `${elem.id}_${++i}_disambiguate`;
      }
      duplicateIdSet.add(key);

      const testId = key;
      const name = (elem as HTMLFormElement).name;

      // if the previous control had "protocol field" selected, then possibly render for a pick list
      const useFieldDefinition = fieldDefinition;
      if (useFieldDefinition) {
        fieldDefinition = null;
        switch (useFieldDefinition.data_type_name) {
          case 'PickList': {
            data[elem.id] = (elem as HTMLTextAreaElement).value.split('\n');
            lastMultiselect = select({
              key,
              name,
              multiple: true,
              id: testId,
              controlAttributes: {
                variant: 'outlined',
              },
              width: 200,
              selectOptions: useFieldDefinition.pick_list_values
                .filter(option => !option.hidden)
                .map(option => ({ id: option.value, label: option.value })),
              passIdOnSelect: true,
            }) as SelectMultipleDef;
            return lastMultiselect;
          }

          case 'Number': {
            data[elem.id] = elem.textContent;
            return numberInput({
              key,
              name,
              id: testId,
              valueType: 'string',
              controlAttributes: {
                variant: 'outlined',
              },
            });
          }
        }
        fieldDefinition = null;
      }

      // create a JSON layout object for a select (pulldown menu) control
      if (elem.tagName === 'SELECT') {
        const selectElem = elem as HTMLSelectElement;

        if (key.startsWith('protocol_field_')) {
          const selectedOption = selectElem.selectedOptions[0].value.toLowerCase();
          fieldDefinition = fieldDefinitions.find(def => def.name.toLowerCase() === selectedOption);
        }

        if (key.startsWith('protocol_readout_operator_')) {
          // determine if the next field should be numeric or not based on the operators
          readoutFieldIsNumber = (elem as HTMLSelectElement).options?.[2]?.value === '<';
        }

        const selectOptions = this.scrapeMenuOptions(selectElem);
        const selectedValue = selectElem.options[Math.max(0, selectElem.selectedIndex)]?.value;
        if (selectedValue !== undefined) {
          data[key] = selectedValue;
        }

        let additionalSelectOptions: AnyObject = {};
        if (key.startsWith('protocol_id_')) {
          additionalSelectOptions = {
            typeahead: true,
            width: 450,
            placeholder: selectElem.options[0].text,
            valueType: 'auto',
          };
          selectOptions.shift();
        }

        const multiple = name.startsWith('protocol_conditions[]');
        if (multiple) {
          data[key] = [data[key]];
          additionalSelectOptions.width = 400;
        }

        const selectControl = select({
          key,
          selectOptions,
          id: testId,
          name,
          multiple,
          controlAttributes: {
            variant: 'outlined',
          },
          ...additionalSelectOptions,
          passIdOnSelect: true,
          autoEllipsisMaxWidth: Math.max(additionalSelectOptions.width ?? 0, 400),
        });
        return selectControl;
      }

      if (elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
        const inputElem = elem as HTMLInputElement;
        data[key] = $(inputElem).text() || $(inputElem).val();
        if (key.startsWith('protocol_readout_value_') && readoutFieldIsNumber) {
          return numberInput({
            key,
            name,
            id: testId,
            valueType: 'string',
            controlAttributes: {
              variant: 'outlined',
            },
          });
        } else {
          return textInput({
            key,
            name,
            id: testId,
            controlAttributes: {
              variant: 'outlined',
            },
          });
        }
      }
      return null;
    };

    let lastMultiselect: SelectMultipleDef = null;

    const iterateThroughElems = (elem: HTMLElement, target: RowColumnDef, prefixLabel = '') => {
      if (elem && elem.style.display !== 'none') {
        // Scott sez.... roughly detect if the element contains any text that's not wrapped inside a tag.
        // If so, we'll insert this as a label next before the next control (e.g. "Comment = [ (any value) ]"
        if ((elem.tagName === 'DIV' || elem.tagName === 'SPAN') && !elem.id.startsWith('run_dates_')) {
          const h = elem.outerHTML.trim();
          const start = h.indexOf('>') + 1;
          const c = h.substring(start, h.length - elem.tagName.length - 3).trim();
          if (c[0] !== '<') {
            prefixLabel = c.substring(0, c.indexOf('<'));
          }
        }

        if (elem.tagName === 'DIV' && elem.dataset.criterionIndex !== undefined && elem.parentElement.id === 'protocol_criteria') {
          // main criteria row
          fieldDefinition = null;
          const newTarget = row({ className: 'criterion-container' }, [
            CDDElements.deleteIconButton({
              key: `criterion_${layout.children.length + 1}`,
              onClickButton: () => {
                this.removeMultipleSelectCloneCriteriaAfter(elem);
                $(elem).find('.cancel').trigger('click');
              },
            }),
          ]);
          target.children.push(newTarget);
          target = newTarget;
          termIds.push(elem.dataset.criterionIndex);
        } else if (elem.className === 'column' &&
          elem.id.startsWith('protocol_criterion_type_specific_content_') && $(elem).height() > 30
        ) {
          // column containing additional rows
          const newTarget = column({}, []);
          target.children.push(newTarget);
          target = newTarget;
          if (prefixLabel) {
            target.children.push(typography({ label: prefixLabel }));
            prefixLabel = '';
          }
        } else if (elem.id.startsWith('protocol_span_') || elem.className === 'selection') {
          // additional rows in column
          const newTarget = row({}, []);
          target.children.push(newTarget);
          target = newTarget;
        } else if (elem.tagName === 'SELECT' || elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
          // input controls
          if (prefixLabel) {
            target.children.push(typography({ label: prefixLabel }));
            prefixLabel = '';
          }
          const control = createLayoutForControl(elem);
          if (control) {
            if (elem.id.startsWith('run_after_')) {
              target.children.push(typography({ label: 'from' }));
            }
            if (elem.id.startsWith('protocol_readout_junction_')) {
              const rowNum = layout.children.length - 1;
              control.className = 'protocol_readout_junction';
              layout.children.splice(rowNum, 0, row({}, [control]));
            } else {
              target.children.push(control);
            }
            if (elem.id.startsWith('run_after_')) {
              target.children.push(typography({ label: 'to' }));
            }
          }
        } else if (elem.tagName === 'SPAN' && elem.textContent && !elem.childElementCount) {
          target.children.push(typography({ label: elem.textContent }));
        }
        for (const childElem of elem.children) {
          iterateThroughElems(childElem as HTMLElement, target, prefixLabel);
        }
        if (elem.parentElement.id === 'protocol_criteria') {
          const combined = this.tryToCombinePreviousLastCriterionIntoMultiselect(layout, data);
          if (combined) {
            elem.dataset.combined = 'true';
          }
          while (termIds.length > layout.children.length) {
            termIds.pop();
          }

          const layoutCriteria = layout.children[layout.children.length - 1];
          this.getControlsFromLayout(layoutCriteria as RowColumnDef).forEach((control) => {
            if (control.key) {
              this.dataKeyToCriteriaInDOM[control.key] = [
                ...this.dataKeyToCriteriaInDOM[control.key] ?? [],
                elem,
              ];
            }
          });
        }
      }
    };
    iterateThroughElems(document.getElementById('protocol_criteria'), layout);

    runInAction(() => {
      this.layout = layout;
      this.data = data;
      this.termIds = termIds;
    });
  }

  handleSetValue(path: string, data: object, value: FieldValueType, previousValue: FieldValueType) {
    /**
     * The user has changed a MUI input value. Find the corresponding element in the hidden form and
     * set its value. One day a developer will look at this and question my sanity. I don't blame them,
     * I would as well. But there was no easy path to make a MUI based UI (with typeahead etc) without
     * destructuring the Rails code and no one had bandwidth or desire to do that.
     */
    if (Array.isArray(value) && Array.isArray(previousValue)) {
      if (!value.length) {
        (value as string[]).push(''); // no selected values means "any value"
      }
      const containsAnyValue = (arr: Array<string|number>) => {
        return arr.some(item => ('' + item).trim() === '');
      };
      if (containsAnyValue(previousValue) && value.length > 1) {
        // if we had "any value" previously selected and have selected a new value, remove the "any value"
        value = value.filter(item => ('' + item).trim() !== '');
        DDFormUtils.setValue(path, data, value);
      } else {
        if (containsAnyValue(value)) {
          // if we've selected "any value" then clear the other values
          value = [''];
          DDFormUtils.setValue(path, data, value);
        }
      }
    }
    const elem = this.findAssociatedElem(path);
    if (!elem) {
      console.error("Couldn't find element for path", path);
      return;
    }

    if (path.startsWith('protocol_id_') || path.startsWith('protocol_criterion_type_')) {
      // when changing protocols, remove all multiple select hidden form criteria
      this.removeMultipleSelectCloneCriteriaAfter(elem);
    }

    let layoutElem: ElementDef = null;
    let layoutElemTopParent: RowColumnDef = null;
    let layoutElemTestParent: RowColumnDef = null;

    DDFormUtils.iterateThroughLayout(this.layout, (elem) => {
      if (this.layout.children.includes(elem as RowColumnDef)) {
        layoutElemTestParent = elem as RowColumnDef;
      }
      if (elem.key === path) {
        layoutElem = elem;
        layoutElemTopParent = layoutElemTestParent;
      }
    });

    if (layoutElem.type === 'multiselect') {
      // when changing a multiple select in a criterion, we need to manipulate the hidden form by changing, adding or removing
      // search terms. So if we change m1 to [a, b], we need to ensure that there are two search terms: m1 = a OR m1 = b.
      //
      // However, a criterion can contain multiple multiple selects. so if m1 = [a, b] and m2 = [x, y], we need to ensure that
      // we have (m1 = a AND m2 = x) OR (m1 = a AND m2 = y) OR (m1 = b AND m2 = x) OR (m1 = b AND m2 = y).
      // et cetera!

      const domCriteria = this.dataKeyToCriteriaInDOM[path]; // the hidden form elements associated with this criteria

      // find all the mutliselect values in this multiple select's criteria row
      const multiselectValues = this.getCriteriaValuesFromLayout(layoutElemTopParent).filter(item => Array.isArray(item));
      if (!multiselectValues.length) {
        console.error('No multiselect values found'); // shouldn't happen
        return;
      }

      const cartesianProduct = (arrays: Array<Array<string>>) => {
        return arrays.reduce((acc, current) => {
          const res = [];
          acc.forEach(ac => {
            current.forEach(c => {
              // Concatenate each element of the current array with each accumulated array
              res.push([...ac, c]);
            });
          });
          return res;
        }, [[]]); // Start with an array containing an empty array
      };

      // modify the hidden form so it has rows for all combinations of the selected multiselect values
      const product = cartesianProduct(multiselectValues);

      const setValuesToRow = (row: HTMLElement, values: Array<string>) => {
        let index = 0;
        this.getControlsFromLayout(layoutElemTopParent).forEach((control, i) => {
          if (control.type === 'multiselect') {
            const selectElem = row.querySelector(`[name="${control.name}"]`) as HTMLSelectElement;
            selectElem.value = values[index++];
          }
        });
      };

      let nextId = $('#protocol_criteria').children().length + 1;
      for (let i = 0; i < product.length; i++) {
        if (i < domCriteria.length) {
          const domCriterion = domCriteria[i];
          setValuesToRow(domCriterion, product[i] as Array<string>);
        } else {
          const target = domCriteria[0];
          const newCriterionElement = target.cloneNode(true) as HTMLElement; // true for a deep clone
          newCriterionElement.dataset.combined = 'true';

          const updateElementIds = (element: HTMLElement) => {
            // Check if the provided element is indeed an HTMLElement
            if (!(element instanceof HTMLElement)) {
              console.error('The provided argument is not an HTMLElement.');
              return;
            }

            // Iterate through all child elements
            for (const child of element.children) {
              // Check if the child has an id attribute
              if (child.hasAttribute('id')) {
                // Set the id to the tag name plus the nextId, and increment nextId
                child.id = `${child.id}_unique_${nextId++}`;
              }
              // Recursively update IDs for children of the current child
              updateElementIds(child as HTMLElement);
            }
          };
          updateElementIds(newCriterionElement);

          domCriteria[domCriteria.length - 1].after(newCriterionElement);

          const junction = newCriterionElement.querySelector('.protocol_readout_junction select') as HTMLSelectElement;
          if (!junction) {
            // eslint-disable-next-line no-unsafe-innerhtml/no-unsafe-innerhtml
            newCriterionElement.insertAdjacentHTML('afterbegin',
            `<div class="protocol_readout_junction" style="display: block;">
               <select name="protocol_junction[]" id="protocol_readout_junction_${nextId++}"><option value="and">and</option>
               <option selected="selected" value="or">or</option></select>
             </div>`);
          } else {
            junction.value = 'or';
          }
          setValuesToRow(newCriterionElement, product[i]);
        }
      }

      for (let i = product.length; i < domCriteria.length; i++) {
        domCriteria[i].remove();
      }
      return;
    }

    switch (elem.tagName) {
      case 'SELECT': {
        const selectElem = elem as HTMLSelectElement;

        let selectIndex = 0;
        for (let i = 0; i < selectElem.options.length; i++) {
          const option = selectElem.options[i];
          if (('' + value) === option.value) {
            selectIndex = i;
            break;
          }
        }
        selectElem.selectedIndex = selectIndex;
        $(selectElem).trigger('change');
        break;
      }
      case 'INPUT': {
        const input = elem as HTMLTextAreaElement;
        $(input).val(value as string);
        break;
      }

      case 'TEXTAREA': {
        const textArea = elem as HTMLTextAreaElement;
        textArea.value = value as string;
        break;
      }
    }

    return true;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }
}
