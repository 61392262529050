import { Grid } from '@mui/material';
import { PageElementDef } from './types/pageElementDef';
import { ElementType } from './types';
import { StepperDef } from './types/stepperDef';
import { RowColumnDef } from './types/rowColumnDef';
import { TextInputDef } from './types/textInputDef';
import { SelectSingleDef } from './types/selectSingleDef';
import { CustomFieldDef } from './types/customFieldDef';
import { SelectMultipleDef } from './types/selectMultipleDef';
import { TypographyDef } from './types/typographyDef';
import { ToggleDef } from './types/toggleDef';
import { CheckboxDef } from './types/checkboxDef';
import { ButtonDef } from './types/buttonDef';
import { TreeDef, TreeItemDef } from './types/treeDef';
import { TabsDef } from './types/tabsDef';
import { RadioGroupDef } from './types/radioGroupDef';
import { NumberInputDef } from './types/numberInputDef';
import { DateInputDef } from './types/dateInputDef';

// Generic wrapper allow a function to take one or two parameters, with the first being optional.
// Basically, it's nicer to pass options first, because the children may be very long. But you don't
// always need to pass in options (say, when creating a row or column).
function optionalFirstFunctionFactory<X, Y, R>(defaultX: X, f: (x: X, y: Y) => R) {
  return (x: X | Y | undefined, y?: Y) => {
    if (y === undefined) {
      return f(defaultX, x as Y);
    } else {
      return f(x as X, y);
    }
  };
}

const page = (
  options: Pick<PageElementDef, 'label'>,
  children?: Array<ElementType>,
) => {
  const result: PageElementDef = {
    type: 'page',
    contents: {
      type: 'column',
      spacing: 1,
      children: children ?? [],
    },
    ...options,
  };
  return result;
};

const tabs = (
  options: Pick<TabsDef, 'key'> & Partial<TabsDef>,
  children?: Array<PageElementDef>,
) => {
  const result: TabsDef = {
    type: 'tabs',
    children: children ?? [],
    ...options,
  };
  return result;
};

const stepper = (
  options: Pick<StepperDef, 'key'> & Partial<StepperDef>,
  children?: Array<PageElementDef>,
) => {
  const result: StepperDef = {
    type: 'stepper',
    children: children ?? [],
    ...options,
  };
  return result;
};

type RowColumnDefOptions = Partial<RowColumnDef> & { controlAttributes?: Partial<React.ComponentProps<typeof Grid>> };

const row = optionalFirstFunctionFactory<RowColumnDefOptions, Array<ElementType>, RowColumnDef>({},
  (options: RowColumnDefOptions, children: Array<ElementType>) => {
    const result: RowColumnDef = {
      type: 'row',
      children: children ?? [],
      ...options,
    };
    return result;
  },
);

const column = optionalFirstFunctionFactory<RowColumnDefOptions, Array<ElementType>, RowColumnDef>({},
  (options: RowColumnDefOptions, children: Array<ElementType>) => {
    const result: RowColumnDef = {
      type: 'column',
      children: children ?? [],
      ...options,
    };
    return result;
  },
);

const textInput = (
  options: Partial<TextInputDef> & Pick<TextInputDef, 'key'>,
) => {
  const result: TextInputDef = {
    type: 'text',
    ...options,
  };
  return result;
};

const numberInput = (
  options: Partial<NumberInputDef> & Pick<NumberInputDef, 'key'>,
) => {
  const result: NumberInputDef = {
    type: 'number',
    ...options,
  };
  return result;
};

const select = (options: { multiple?: boolean } & Partial<SelectMultipleDef> & Pick<SelectSingleDef, 'key'>) => {
  if (options.multiple) {
    return {
      type: 'multiselect',
      ...options,
    } as SelectMultipleDef;
  } else {
    return {
      type: 'select',
      ...options,
    } as SelectSingleDef;
  }
};

const custom = (option: Pick<CustomFieldDef, 'render'> & Partial<CustomFieldDef>) => {
  return {
    type: 'custom',
    ...option,
  } as CustomFieldDef;
};

const typography = (
  option: Partial<TypographyDef> & Pick<TypographyDef, 'label'>,
) => {
  return {
    type: 'typography',
    ...option,
  } as TypographyDef;
};

const toggle = (
  option: Partial<ToggleDef> & Pick<ToggleDef, 'key' | 'label'>,
) => {
  return {
    type: 'toggle',
    ...option,
  } as ToggleDef;
};

const checkbox = (
  option: Partial<CheckboxDef> & Pick<CheckboxDef, 'key' | 'label'>,
) => {
  return {
    type: 'checkbox',
    ...option,
  } as CheckboxDef;
};

const button = (
  option: Partial<ButtonDef> & Pick<ButtonDef, 'key' | 'label'>,
) => {
  return {
    type: 'button',
    ...option,
  } as ButtonDef;
};

const tree = (
  option: Partial<TreeDef> & Pick<TreeDef, 'key'>,
  children: Array<TreeItemDef>) => {
  return {
    type: 'tree',
    ...option,
    children,
  } as TreeDef;
};

const treeItem = (
  option: Partial<TreeItemDef> & Pick<TreeItemDef, 'key'>,
  children: Array<TreeItemDef> /* TreeItemDef['children'] */ = []) => {
  return {
    type: 'treeItem',
    ...option,
    children,
  };
};

const radioGroup = (
  option: Partial<RadioGroupDef> & Pick<RadioGroupDef, 'key' | 'label' | 'selectOptions'>,
) => {
  return {
    type: 'radioGroup',
    ...option,
  } as RadioGroupDef;
};

const dateInput = (
  option: Partial<DateInputDef> & Pick<DateInputDef, 'key' | 'label'>,
) => {
  return {
    type: 'date',
    ...option,
  } as DateInputDef;
};

export const layoutBuilder = {
  tabs,
  stepper,
  page,
  row,
  column,
  textInput,
  numberInput,
  select,
  custom,
  typography,
  toggle,
  checkbox,
  button,
  tree,
  treeItem,
  radioGroup,
  dateInput,
};
