import { RootStore } from '@/stores/rootStore';
import { getCurrentUser } from './getCurrentUser';

export class EnvironmentStore {
  constructor(public readonly root: RootStore) {
  }

  get currentUser() {
    return getCurrentUser();
  }
}
