import {
  CustomAminoAcidsSchema,
  EditCustomAminoAcidsSchema,
} from './types';

export class CustomAminoAcidsUtils {
  static convertToEditCustomAminoAcid(customNucleotide: CustomAminoAcidsSchema): EditCustomAminoAcidsSchema {
    return {
      ...customNucleotide,
    };
  }
}
