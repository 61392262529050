import { FieldDefinition } from '@/FieldDefinitions/types';
import {
  EditInventoryEvent,
  EditSample,
  FieldHash,
  InventoryEvent,
} from '@/Samples/types';
import { ProcessedInventoryEntries } from '../components/types';
import { InventoryStore } from './inventoryStore';

export class InventoryStoreHelper {
  static convertNameKeysToFieldIds(
    fields: FieldHash,
    fieldDefinitions: FieldDefinition[],
  ): InventoryEvent['inventory_event_fields'] {
    const relevantDefs = fieldDefinitions.filter(
      (fieldDefinition) => fields[fieldDefinition.name] !== undefined,
    );
    return Object.fromEntries(
      relevantDefs.map((def) => [def.id, fields[def.name]]),
    );
  }

  static convertToInventorySample(
    inventoryEntry: Partial<ProcessedInventoryEntries[number]>,
    inventory_event_field_definitions: InventoryStore['inventory_field_definitions']['Event'],
  ): EditSample {
    return {
      ...inventoryEntry,
      inventory_sample_fields_orig: inventoryEntry.inventory_sample_fields,
      inventory_events: (inventoryEntry.inventory_events || []).map((event) =>
        InventoryStoreHelper.convertEntryToInventoryEvent(
          event,
          inventoryEntry.id,
          inventory_event_field_definitions,
        ),
      ),
      inventory_sample_fields: inventoryEntry.inventory_sample_fields,
      inventory_event_fields: inventoryEntry.inventory_event_fields,
      batch_id: inventoryEntry.batch.id,
      batch_name: inventoryEntry.batch.name,
      created_at: inventoryEntry.sample_created_date,
      created_by_user_full_name:
        inventoryEntry.sample_created_by_user_full_name,
      modified_at: inventoryEntry.sample_updated_date,
      modified_by_user_full_name:
        inventoryEntry.sample_updated_by_user_full_name,
      name: inventoryEntry.name,
      units: inventoryEntry.units,
      location: inventoryEntry.location,
      is_single_use: inventoryEntry.is_single_use,
    };
  }

  static convertEntryToInventoryEvent(
    val: ProcessedInventoryEntries[number]['inventory_events'][number],
    sample_id: number,
    inventory_event_field_definitions: InventoryStore['inventory_field_definitions']['Sample'],
  ): EditInventoryEvent {
    return {
      ...val,
      id: val.event_id,
      created_at: val.event_created_date,
      modified_at: val.event_modified_date,
      created_by_user_full_name: val.event_created_by_user_full_name,
      modified_by_user_full_name: val.event_modified_by_user_full_name,
      inventory_sample_id: sample_id,
      field_definitions: inventory_event_field_definitions,
      inventory_event_fields: val.inventory_event_fields,
    };
  }
}
