/* eslint-disable multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import React from 'react';
import './Mixture.sass';
import { observer } from 'mobx-react';
import { MixturesStore } from './mixturesStore';

type Props = {
  store: MixturesStore,
  arrcomp: Mixtures.ArrangeMixtureComponent,
  offsetX: number,
  offsetY: number,
};

@observer
export class UnderlayNode extends React.Component<Props> {
  constructor(props: Props) { // eslint-disable-line @typescript-eslint/no-useless-constructor
    super(props);
  }

  public render(): JSX.Element {
    const { store, arrcomp, offsetX, offsetY } = this.props;
    const { selectedOrigin } = store;

    if (JSON.stringify(arrcomp.origin) != JSON.stringify(selectedOrigin)) return (<></>);

    const box = arrcomp.boundary.withOffsetBy(offsetX, offsetY);
    const chkbox = arrcomp.collapseBox;
    if (chkbox) box.w -= chkbox.w;

    const PAD = 1;
    return (
      <div
        key="underlay-selected"
        className="MixtureEditor-selectedhalo"
        style={{
          position: 'absolute',
          left: `${box.x - PAD}px`,
          top: `${box.y - PAD}px`,
          width: `${box.w + 2 * PAD}px`,
          height: `${box.h + 2 * PAD}px`,
        }}
      />
    );
  }
}
