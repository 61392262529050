import { AxiosError } from 'axios';

type ErrorType = ErrorEvent | AxiosError;
type ErrorHandler = (error: ErrorType) => boolean | void;
const subscribers: Array<ErrorHandler> = [];

const errorsToBroadcast: Array<ErrorType> = [];

// Send the error to the subscribers, from most recent to oldest. If any returns true, that means
// it's handled and we should stop sending.
//
// Note that this does not happen immediately, but in the next cycle, allowing the broadcast to be
// cancelled if the error is handled synchronously.
//

let broadcastTimeoutId = null;

export const broadcastUnhandledError = (error: ErrorType) => {
  if (error instanceof ErrorEvent && (error.target as any)?.nodeName === 'IMG') { // eslint-disable-line @typescript-eslint/no-explicit-any
    // if the error is on an IMG tag, don't report it. It's just a broken link, which isn't uncommon or a critical failure, and
    // it should be displayed with a broken icon.
    return;
  }

  // stop any pending broadcasts
  if (broadcastTimeoutId) {
    clearTimeout(broadcastTimeoutId);
    broadcastTimeoutId = null;
  }
  errorsToBroadcast.push(error);

  // set up a new pending broadcast
  broadcastTimeoutId = setTimeout(() => {
    broadcastTimeoutId = null;
    if (errorsToBroadcast.length) {
      errorsToBroadcast.forEach(error => {
        for (let i = subscribers.length - 1; i >= 0; i--) {
          try {
            // call subscriber, if it returns true then don't pass to next
            if (subscribers[i](error) === true) {
              break;
            }
          } catch (e) {
            // we don't want handling an error to trigger an error, or an infinite loop may result.
            // so catch any errors here, and display them in the console
            console.error(e);
          }
        }
      });
      // clear array
      errorsToBroadcast.splice(0, errorsToBroadcast.length);
    }
  });
};

export const cancelBroadcastError = (error: ErrorType) => {
  const toRemove = errorsToBroadcast.indexOf(error);
  if (toRemove !== -1) {
    errorsToBroadcast.splice(toRemove, 1);
  }
};

export const subscribeToUnhandledErrors = (func: ErrorHandler) => {
  subscribers.push(func);
};

export const unsubscribeToUnhandledErrors = (func: ErrorHandler) => {
  const toRemove = subscribers.indexOf(func);
  if (toRemove !== -1) {
    subscribers.splice(toRemove, 1);
  }
};
