import { EditValueType, ReadoutDefinitionObject, ReadoutDefinitionType } from './types';

function calculationType(value: ReadoutDefinitionObject | EditValueType) {
  if ((value as ReadoutDefinitionObject).placeholder_for_calculation) {
    return (value as ReadoutDefinitionObject).calculation_type;
  }
}

function isDoseResponse(value: ReadoutDefinitionObject | EditValueType) {
  return (value as EditValueType).dose_response_calculation_object !== undefined ||
  calculationType(value) === 'DoseResponseCalculation';
}

function isCalculated(value: ReadoutDefinitionObject | EditValueType) {
  return (value as EditValueType).custom_calculation_object !== undefined ||
  calculationType(value) === 'CustomCalculation';
}

export class ReadoutDefinitionUtils {
  static getType(value: (ReadoutDefinitionObject | EditValueType)) : ReadoutDefinitionType {
    if (isDoseResponse(value)) {
      return 'dose-response';
    }
    if (isCalculated(value)) {
      return 'calculated';
    }
    return 'readout';
  }

  static getPickListSummary(value: { pick_list_values?: Array<{value: string, _destroy?: boolean}> }) {
    if (!value.pick_list_values) {
      return '';
    }

    const pick_list_values = value.pick_list_values.filter(v => !v._destroy);

    if (pick_list_values.length >= 10) {
      return `Pick List contains ${pick_list_values.length} items`;
    }

    return `Values: ${pick_list_values.map(v => `"${v.value}"`).join(', ')}`;
  }
}
