import { makeAutoObservable } from 'mobx';
import { FormDefinition } from '@/Annotator/data/forms';
import { RootStore } from '@/stores/rootStore';
import { accountsService } from '@/Accounts/accountsServices';
import { EditFormDefinitionStore } from './editFormDefinitionStore';
import { ColumnSortDef } from '@/components';

/**
 * A store for managing the form definition data, that is, the list of forms.
 *
 * Access this through getRootStore().formDefinitionStore.
 */
export class FormDefinitionsStore {
  root: RootStore;
  inited = false;
  editFormDefinitionStore: EditFormDefinitionStore;
  loading = 0;
  vaultId: number;
  formDefinitions: Array<FormDefinition> = [];
  sortBy: ColumnSortDef = { id: 'name', direction: 'asc' };

  constructor(root: RootStore) {
    this.root = root;
    this.editFormDefinitionStore = new EditFormDefinitionStore(root);
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setVaultId(value: number) {
    this.vaultId = value;
    if (value) {
      this.loadFormDefinitions();
    }
  }

  setSortBy(value: ColumnSortDef) {
    this.sortBy = value;
  }

  get displayRows() {
    return this.formDefinitions;
  }

  setFormDefinitions(formDefinitions: Array<FormDefinition>) {
    this.formDefinitions = formDefinitions;
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  async loadFormDefinitions() {
    if (this.vaultId) {
      try {
        this.incrementLoading();
        const response = await accountsService.getFormDefinitions(this.vaultId);
        this.setFormDefinitions(response.data as any as Array<FormDefinition>); // eslint-disable-line @typescript-eslint/no-explicit-any
      } finally {
        this.decrementLoading();
      }
    }
  }
}
