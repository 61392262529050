import { LocationNode, LocationValue } from '@/Samples/types';
import { StringOrNumber } from '@/types';
import { runInAction } from 'mobx';
import { deepClone } from '../../../Annotator/data/utils';

// if there are more than this number of total tree items, start with all nodes collapsed
const INITALLY_COLLAPSED_THRESHOLD = 100;

let nextSyntheticId = -2;

export class LocationUtils {
  // is this location a box (organized or unorganized)?
  static isNodeBox(node: LocationNode) {
    return node.position_limit > 0 || node.num_columns > 0 || node.capacity > 0 || node.organized === false;
  }

  // is this location a box (organized or unorganized)?
  static isNodeLocation(node: LocationNode) {
    return !this.isNodeBox(node);
  }

  // determine which parent nodes should be expanded based on the current location
  static getExpandedNodes(value?: LocationValue | LocationNode, locations: Array<LocationNode> = []) {
    const determineExpandedNodeForLocation = (node: LocationNode, expanded: Array<string> = []) => {
      if (value.id === node.id) {
        return [...expanded, '' + node.id];
      }
      const nodeChildren = node.children ?? [];
      for (let i = 0; i < nodeChildren.length; i++) {
        const child = nodeChildren[i];
        const result = determineExpandedNodeForLocation(child, [...expanded, '' + node.id]);
        if (result) {
          return result;
        }
      }
    };

    if (value && value.id) {
      return locations.map(location => determineExpandedNodeForLocation(location))
        .filter(result => !!result)
        .flat();
    }
    return this.getAllExpandedNodes(locations);
  }

  static getAllExpandedNodes(locations: Array<LocationNode>) {
    const result = [];
    let totalItems = 0;
    if (locations) {
      const addIds = (node: LocationNode) => {
        ++totalItems;
        if (node.id && !node.position_limit) {
          result.push('' + node.id);
        }
        if (node.children) {
          node.children.forEach(child => addIds(child));
        }
      };
      locations.forEach(location => addIds(location));
    }
    if (totalItems > INITALLY_COLLAPSED_THRESHOLD) {
      // to prevent sluggish behavior, start with all nodes collapsed rather than expanded
      // when above a certain threshold - perhaps we eventually replace/fix SortableTreeView to be
      // virtualized
      return [result[0]];
    }
    return result;
  }

  // locate the node with the specified locationId in the hierarchy of nodes
  static findSelectedLocationNode(locationId: StringOrNumber, locations: Array<LocationNode>) {
    function findSelectedLocationRecursive(location: LocationNode): LocationNode | undefined {
      if (location.id == locationId) {
        return location;
      }
      const nodeChildren = location.children ?? [];
      for (let i = 0; i < nodeChildren.length; i++) {
        const child = nodeChildren[i];
        const result = findSelectedLocationRecursive(child);
        if (result) {
          return result;
        }
      }
    }
    return findSelectedLocationRecursive({
      id: 0,
      display_order: 0,
      value: '',
      children: locations,
    });
  }

  static ensureParentsOnChildren(locationNode: LocationNode | Array<LocationNode>, setToNull = false) {
    const setParentOnChildren = (node: LocationNode) => {
      (node.children ?? []).forEach(child => {
        child.parent = setToNull ? null : node;
        setParentOnChildren(child);
      });
    };
    (Array.isArray(locationNode) ? locationNode : [locationNode]).forEach(node => {
      setParentOnChildren(node);
    });
  }

  static getLabelForLocation(location: LocationValue, locations: Array<LocationNode>) {
    locations.forEach(locationNode => this.ensureParentsOnChildren(locationNode));
    const locationNode = this.findSelectedLocationNode(location.id, locations);
    let result = '';
    if (locationNode) {
      if (locationNode.num_columns && location.position) {
        const position = location.position - 1;
        const col = position % locationNode.num_columns;
        const row = Math.floor(position / locationNode.num_columns);
        result = `${location.position}/${String.fromCharCode(65 + row)}${col + 1}`;
      }

      let parent = locationNode;
      while (parent) {
        result = `${parent.value}${result ? ` > ${result}` : ''}`;
        parent = parent.parent;
      }
    }
    return result;
  }

  static findFirstBox(node: LocationNode) {
    if (this.isNodeBox(node)) {
      return node;
    }
    if (node.children) {
      for (const child of node.children) {
        const result = this.findFirstBox(child);
        if (result) {
          return result;
        }
      }
    }
    return node;
  }

  static isNameLegal(name: string, location: LocationNode) {
    if (!name.length) {
      return false;
    }
    if (name.includes('>')) {
      return 'Location name cannot include the ">" character.';
    }

    if ((location.parent?.children ?? []).some(test => test.value === name && test !== location)) {
      // if (location.children.some(test => test.value === name)) {
      return 'Name is already taken.';
    }
    return true;
  }

  static addLocation(destination: LocationNode, source: Partial<LocationNode> & { value: string }) {
    if (destination.num_columns) {
      destination = destination.parent;
    }
    destination.children = destination.children ?? [];
    const result: LocationNode = {
      ...source,
      id: nextSyntheticId--,
      display_order: destination.children.length,
      parent: destination,
    };
    destination.children.push(result);
    return result;
  }

  static duplicateLocation(source: LocationNode, destination: LocationNode) {
    const value = this.ensureNewNameUniqueness(source.value, destination);

    const duplicated: LocationNode = ({
      ...source,
      id: nextSyntheticId--,
      parent: null,
      value,
      display_order: (destination.children ?? []).length + 1,
      children: [],
      filled_position_count: 0,
    });

    const addDupChildren = (node: LocationNode, dest: LocationNode) => {
      const dupNode: LocationNode = {
        ...node,
        id: nextSyntheticId--,
        children: [],
        parent: null,
        filled_position_count: 0,
      };
      dest.children.push(dupNode);
      if (node.children) {
        node.children.forEach(child => addDupChildren(child, dupNode));
      }
    };

    source.children.forEach(child => addDupChildren(child, duplicated));
    destination.children.push(duplicated);

    return duplicated;
  }

  static ensureNewNameUniqueness(value: string, parent: LocationNode, ignoreId: StringOrNumber = null) {
    const children = parent.children.filter(node => node.id != ignoreId);

    const existing = children.find(child => child.value === value);
    if (existing) {
      // strip out any trailing numbers from value
      const match = value.match(/^(.*?)(\d+)$/);
      if (!match) {
        // if there's no trailing number, use the ensureDragNameUniqueness algorithm
        return this.ensureDragNameUniqueness(value, parent, ignoreId);
      }
      if (match) {
        value = match[1];
      }

      for (let index = 1 + (children?.length ?? 0); index < 10000; index++) {
        const testValue = `${value}${index}`;
        const testExisting = children.find(child => child.value === testValue);
        if (!testExisting) {
          return testValue;
        }
      }
    }
    return value;
  }

  static ensureDragNameUniqueness(value: string, parent: LocationNode, ignoreId: StringOrNumber = null) {
    const children = parent.children.filter(node => node.id != ignoreId);

    const existing = children.find(child => child.value === value);
    if (existing) {
      for (let index = 1; index < 10000; index++) {
        const testValue = `${value} (${index})`;
        const testExisting = children.find(child => child.value === testValue);
        if (!testExisting) {
          return testValue;
        }
      }
    }
    return value;
  }

  static countChildren(node: LocationNode): { used: number, total: number } {
    if (this.isNodeBox(node)) {
      return { used: node.filled_position_count, total: node.position_limit ?? node.capacity };
    }
    const counts = { used: 0, total: 0 };
    (node.children ?? []).forEach(child => {
      const childCounts = this.countChildren(child);
      counts.used += childCounts.used;
      counts.total += childCounts.total;
    });
    return counts;
  }

  static availableFeaturesAtLevel(node: LocationNode) {
    const nodeIsBox = this.isNodeBox(node);
    const nodeContainsBoxes =
      (node.children ?? []).some(child => this.isNodeBox(child));
    const nodeIsTop = !node.parent;
    const deleteDisabled = this.countChildren(node).used > 0;

    return {
      addLocation: !nodeIsBox && !nodeContainsBoxes,
      addBox: !nodeIsBox && !!node.parent,
      duplicate: !nodeIsTop,
      delete: !nodeIsTop && !node.filled_position_count,
      deleteDisabled,
    };
  }

  static deleteNode(nodes: Array<LocationNode>, nodeId: StringOrNumber) {
    const index = nodes.findIndex(node => ('' + nodeId) === ('' + node.id));
    if (index > -1) {
      nodes.splice(index, 1);
    } else {
      nodes.forEach(node => {
        if (node.children) {
          this.deleteNode(node.children, nodeId);
        }
      });
    }
  }

  static createNew(destination: LocationNode, isBox: boolean, organized = true) {
    const value = this.ensureNewNameUniqueness(
      (isBox ? 'Box' : 'Location') + ' ' + (destination.children.length + 1), destination);

    const num_rows = isBox ? 9 : undefined;
    const num_columns = isBox ? 9 : undefined;
    const position_limit = isBox ? num_rows * num_columns : undefined;
    const result: LocationNode = {
      id: nextSyntheticId--,
      parent: destination,
      value,
      display_order: 0,
      children: [],
      filled_position_count: 0,
      num_rows,
      num_columns,
      position_limit,
      organized,
      capacity: isBox ? 100 : undefined,
    };

    runInAction(() => {
      destination.children = [...destination.children, result];
    });

    return result;
  }

  static spliceNode(nodes: Array<LocationNode>,
    draggedNode: LocationNode,
    dragInsert?: {
      intoNodeId?: number;
      index: number;
    }) {
    let result = [] as Array<LocationNode>;

    const filterOutNode = (nodes: Array<LocationNode>, removeNode: LocationNode) => {
      const result = nodes.filter(node => node.id !== removeNode.id);
      nodes.forEach(node => {
        if (node.children) {
          node.children = filterOutNode(node.children, removeNode);
        }
      });
      return result;
    };

    result = filterOutNode(deepClone(nodes), draggedNode);
    if (dragInsert) {
      const { intoNodeId, index } = dragInsert ?? {};

      const insertIntoNodes = (parentElement?: LocationNode) => {
        if (!parentElement) {
          if (intoNodeId === undefined && index !== undefined) {
            draggedNode.parent = parentElement;
            result.splice(index, 0, draggedNode);
          }
          result.forEach(node => {
            insertIntoNodes(node);
          });
        } else {
          if (parentElement.id === intoNodeId) {
            draggedNode.parent = parentElement;
            parentElement.children = parentElement.children ?? [];
            parentElement.children.splice(index, 0, draggedNode);
          }
          (parentElement.children ?? []).forEach(node => {
            insertIntoNodes(node);
          });
        }
      };
      insertIntoNodes();

      result.forEach(node => this.ensureParentsOnChildren(node));
      return result;
    }
  }

  static prepareToSave(nodes: Array<Partial<LocationNode>>) {
    const prepareNode = (node: Partial<LocationNode>) => {
      if (node.id < 0) {
        delete node.id;
      }
      delete node.parent;
      node.children = node.children ?? [];
      node.children.forEach((child, index) => {
        prepareNode(child);
        child.display_order = index;
        child.parent_id = node.id;

        if (child.organized === true) {
          delete child.capacity;
        } else if (child.organized === false) {
          delete child.num_columns;
          delete child.num_rows;
        }
      });
    };
    nodes.forEach((node, index) => {
      prepareNode(node);
      node.display_order = index;
    });
    return nodes;
  }

  static sortByDisplayOrder(nodes: Array<LocationNode>) {
    const sortNodes = (nodes: Array<LocationNode>) => {
      nodes.sort((a, b) => a.display_order - b.display_order);
      nodes.forEach(node => {
        if (node.children) {
          sortNodes(node.children);
        }
      });
    };
    sortNodes(nodes);
  }

  static findNodeById(nodes: Array<LocationNode>, id: StringOrNumber) {
    for (const node of nodes) {
      if (('' + node.id) == ('' + id)) {
        return node;
      }
      if (node.children) {
        const result = this.findNodeById(node.children, id);
        if (result) {
          return result;
        }
      }
    }
  }

  static computeNodeMap(nodes: Array<LocationNode>) {
    const result: Record<StringOrNumber, LocationNode> = {};
    const addToMap = (node: LocationNode) => {
      result[node.id] = node;
      (node.children ?? []).forEach(child => addToMap(child));
    };
    nodes.forEach(node => addToMap(node));
    return result;
  }
}
