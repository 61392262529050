import { Schema, SchemaBranch } from '@/Annotator/data/Schema';
import { FieldDefinitionsForVault, Protocol } from './types';
import { TemplateAssignment, TemplateGroup } from '@/Annotator/data/templates';
import { collapsePrefix, collapsePrefixes, compatiblePropGroupNest, keyPropGroup } from '@/Annotator/data/utils';
import { StringOrNumber } from '@/types';
import { FieldDefinition, FieldDataType } from '@/FieldDefinitions/types';

export enum FilterLayerType {
  ProtocolField,
  OntologyTerm,
}

export interface FilterLayer {
  type: FilterLayerType;
}

export enum FilterLayerProtocolFieldValueType {
  String,
  Number,
}

export enum FilterLayerFieldDataType {
  String = 1,
  Number = 2,
  PickList = 3,
}

export interface FilterLayerProtocolFieldDefinition {
  name: string;
}

export interface FilterLayerProtocolField extends FilterLayer {
  dataType: FilterLayerFieldDataType;
  defn: FilterLayerProtocolFieldDefinition;
  observedValues: string[];
  unionOfValues: string[];
  value: string | string[];
  stringCaseSensitive?: boolean;
  stringWholeMatch?: boolean;
}

export interface FilterLayerOntologyTerm extends FilterLayer {
  propURI: string;
  groupNest: string[];
  valueURIList: string[];
}

export function validateFilterLayer(layer: FilterLayer): boolean {
  if (layer.type == FilterLayerType.ProtocolField) {
    const layerPF = layer as FilterLayerProtocolField;
    return layerPF.dataType != null && layerPF.defn != null;
  } else if (layer.type == FilterLayerType.OntologyTerm) {
    const layerOT = layer as FilterLayerOntologyTerm;
    return layerOT.propURI != null && layerOT.valueURIList != null;
  }
  return false;
}

export interface PresentableField {
  dataType: FilterLayerFieldDataType;
  defn: FieldDefinition;
  observedValues: Set<string>;
  unionOfValues: Set<string>;
  usedInLayer: number; // >=0 if a layer is already claiming it, else null
}

export interface PresentableAssignment {
  assn: TemplateAssignment;
  groupNest: string[];
  isPresent: boolean; // true if it shows up in the protocols
  usedInLayer: number; // >=0 if a layer is already claiming it, else null
}

// goes through the protocols and extracts a list of field definitions: these are reduced by name & type; names may appear more than
// once if the types clash (e.g. string vs number vs picklist) but they will be consolidated as appropriate; the list of protocols should
// be pre-reduced if this isn't the first layer
export function determinePresentableFields(fieldDefinitions: FieldDefinitionsForVault[], layers: FilterLayer[], protocols: Protocol[]): PresentableField[] {
  const idToNumber = (id: StringOrNumber): number => typeof id == 'number' ? id : 0;
  const defnFromID = new Map<number, FieldDefinition>();

  const defnDataType = (defn: FieldDefinition) => {
    if (defn.data_type_name == FieldDataType.Text ||
      defn.data_type_name == FieldDataType.LongText ||
      defn.data_type_name == FieldDataType.File ||
      defn.data_type_name == FieldDataType.BatchLink
    ) return FilterLayerFieldDataType.String;
    if (defn.data_type_name == FieldDataType.Number) return FilterLayerFieldDataType.Number;
    if (defn.data_type_name == FieldDataType.PickList) return FilterLayerFieldDataType.PickList;
    return null;
  };

  const layersUsed = new Map<string, number>();
  for (let n = 0; n < layers.length; n++) {
    if (layers[n].type == FilterLayerType.ProtocolField) {
      const { dataType, defn } = layers[n] as FilterLayerProtocolField;
      layersUsed.set(`${defn.name}:${dataType}`, n + 1);
    }
  }

  const presentables = new Map<string, PresentableField>(); // key-to-instance

  for (const vault of fieldDefinitions) {
    for (const defn of vault.protocol_field_definitions) {
      defnFromID.set(idToNumber(defn.id), defn);

      const dataType = defnDataType(defn);
      const key = `${defn.name}:${dataType}`;
      let pfield = presentables.get(key);
      if (!pfield) {
        pfield = {
          dataType,
          defn,
          observedValues: new Set(),
          unionOfValues: new Set(),
          usedInLayer: layersUsed.get(key),
        };
        presentables.set(key, pfield);
      }
      for (const pick of (defn.pick_list_values || [])) {
        pfield.unionOfValues.add(pick.value);
      }
    }
  }
  for (const protocol of protocols) {
    for (const field of (protocol.protocol_fields || [])) {
      const defn = defnFromID.get(idToNumber(field.protocol_field_definition_id));
      if (!defn) continue;
      const dataType = defnDataType(defn);
      const key = `${defn.name}:${dataType}`;
      const pfield = presentables.get(key);
      if (pfield) pfield.observedValues.add(field.display_string);
    }
  }

  return Array.from(presentables.values()).sort((pf1, pf2) => {
    const cmp = pf1.defn.name.localeCompare(pf2.defn.name);
    if (cmp != 0) return cmp;
    return pf1.dataType - pf2.dataType;
  });
}

// goes through the template schema and figures out what the deal is for each assignment: has it been used in a layer already, and are there
// any protocols that reference it
export function determinePresentableAssignments(schema: Schema, layers: FilterLayer[], protocols: Protocol[]): PresentableAssignment[] {
  if (!schema) return [];

  const propsPresent = new Set<string>();
  for (const protocol of protocols) {
    for (const annot of (protocol.ontology_annotations ?? [])) {
      propsPresent.add(keyPropGroup(annot.prop_uri, annot.group_nest));
    }
  }

  const layersUsed = new Map<string, number>();
  for (let n = 0; n < layers.length; n++) {
    if (layers[n].type == FilterLayerType.OntologyTerm) {
      const { propURI, groupNest } = layers[n] as FilterLayerOntologyTerm;
      layersUsed.set(keyPropGroup(propURI, groupNest), n + 1);
    }
  }

  const presentables: PresentableAssignment[] = [];

  const scanGroup = (group: TemplateGroup, groupNest: string[]) => {
    for (const assn of group.assignments) {
      const key = keyPropGroup(assn.propURI, groupNest);
      presentables.push({
        assn,
        groupNest,
        isPresent: propsPresent.has(key),
        usedInLayer: layersUsed.get(key),
      });
    }
    for (const sg of group.subGroups) scanGroup(sg, [sg.groupURI, ...groupNest]);
  };
  scanGroup(schema.template.root, []);

  return presentables;
}

// for a given assignment indicator, go through all existing protocols and accumulate the total counts for each value URI; the resulting
// dictionary has full URI keys for only those which actually appear, the rest are left out
export function gatherTermCounts(propURI: string, groupNest: string[], protocols: Protocol[]): Record<string, number> {
  const termCounts: Record<string, number> = {};

  for (const protocol of protocols) {
    for (const annot of (protocol.ontology_annotations ?? [])) {
      if (compatiblePropGroupNest(propURI, groupNest, annot.prop_uri, annot.group_nest)) {
        const uri = collapsePrefix(annot.value_uri);
        const count = termCounts[uri] || 0;
        termCounts[uri] = count + 1;
      }
    }
  }

  return termCounts;
}

export function filterLayerIsBlank(layer: FilterLayer): boolean {
  if (layer == null) return null;
  if (layer.type == FilterLayerType.ProtocolField) {
    const { value } = layer as FilterLayerProtocolField;
    return value == null || (Array.isArray(value) && value.every((v) => v == null));
  }
  if (layer.type == FilterLayerType.OntologyTerm) {
    return (layer as FilterLayerOntologyTerm).valueURIList.length == 0;
  }
  return false;
}

// for a list of protocols, go through and knock out any that are disqualified by the current layer
export async function filteredProtocolsByLayer(protocols: Protocol[], fieldDefinitions: FieldDefinitionsForVault[], schema: Schema, layer: FilterLayer): Promise<Protocol[]> {
  if (filterLayerIsBlank(layer)) return protocols;

  const idToNumber = (id: StringOrNumber): number => typeof id == 'number' ? id : 0;
  const defnNameFromID = new Map<number, string>();

  const scanBranch = (branchMap: Map<string, SchemaBranch>, branch: SchemaBranch) => {
    branchMap.set(branch.uri, branch);
    for (const look of branch.children) scanBranch(branchMap, look as SchemaBranch);
  };

  const matchesProtocolFieldString = (protocol: Protocol, layer: FilterLayerProtocolField): boolean => {
    let qstr = (layer.value as string ?? '').trim();
    let tstr = '';
    for (const pfield of (protocol.protocol_fields || [])) {
      if (defnNameFromID.get(idToNumber(pfield.protocol_field_definition_id)) == layer.defn.name) {
        tstr = (pfield.display_string ?? '').trim();
      }
    }
    if (!layer.stringCaseSensitive) [qstr, tstr] = [qstr.toLowerCase(), tstr.toLowerCase()];

    if (layer.stringWholeMatch) {
      return qstr == tstr;
    } else {
      return tstr.includes(qstr);
    }
  };

  const matchesProtocolFieldNumber = (protocol: Protocol, layer: FilterLayerProtocolField): boolean => {
    const [strMin, strMax] = Array.isArray(layer.value) ? layer.value : [null, null];
    const qlo = !Number.isNaN(parseFloat(strMin)) ? parseFloat(strMin) : null;
    const qhi = !Number.isNaN(parseFloat(strMax)) ? parseFloat(strMax) : null;

    for (const pfield of (protocol.protocol_fields || [])) {
      if (defnNameFromID.get(idToNumber(pfield.protocol_field_definition_id)) == layer.defn.name) {
        const tval = parseFloat(pfield.display_string);
        if (Number.isNaN(tval)) return false;
        if (qlo != null && tval < qlo) return false;
        if (qhi != null && tval > qhi) return false;
        return true;
      }
    }

    return false;
  };

  const matchesProtocolFieldPickList = (protocol: Protocol, layer: FilterLayerProtocolField): boolean => {
    const selectedValues = Array.isArray(layer.value) ? layer.value as string[] : [];

    for (const pfield of (protocol.protocol_fields || [])) {
      if (defnNameFromID.get(idToNumber(pfield.protocol_field_definition_id)) == layer.defn.name) {
        return selectedValues.includes(pfield.display_string);
      }
    }

    return false;
  };

  const matchesOntologyProtocol = (protocol: Protocol, branchMap: Map<string, SchemaBranch>, propURI: string, groupNest: string[], valueAbbrevList: string[]): boolean => {
    for (const annot of protocol.ontology_annotations) {
      if (!compatiblePropGroupNest(annot.prop_uri, annot.group_nest, propURI, groupNest)) continue;
      const branch = branchMap.get(collapsePrefix(annot.value_uri));
      if (!branch) return;
      for (let look = branch; look; look = look.parent as SchemaBranch) {
        if (valueAbbrevList.includes(look.uri)) return true;
      }
    }
    return false;
  };

  if (layer.type == FilterLayerType.ProtocolField) {
    for (const vault of fieldDefinitions) {
      for (const defn of vault.protocol_field_definitions) {
        defnNameFromID.set(idToNumber(defn.id), defn.name);
      }
    }

    const layerPF = layer as FilterLayerProtocolField;
    const { dataType } = layerPF;
    if (dataType == FilterLayerFieldDataType.String) return protocols.filter((protocol) => matchesProtocolFieldString(protocol, layerPF));
    else if (dataType == FilterLayerFieldDataType.Number) return protocols.filter((protocol) => matchesProtocolFieldNumber(protocol, layerPF));
    else if (dataType == FilterLayerFieldDataType.PickList) return protocols.filter((protocol) => matchesProtocolFieldPickList(protocol, layerPF));
  }

  if (layer.type == FilterLayerType.OntologyTerm) {
    const { propURI, groupNest, valueURIList } = layer as FilterLayerOntologyTerm;
    if (valueURIList.length == 0) return protocols; // no filters, so leave it unchanged
    const assn = schema?.findAssignment(propURI, groupNest);
    if (!assn) return [];
    const branchMap = new Map<string, SchemaBranch>();
    for (const branch of await schema.composeBranch(assn)) scanBranch(branchMap, branch);
    return protocols.filter((protocol) => matchesOntologyProtocol(protocol, branchMap, propURI, groupNest, collapsePrefixes(valueURIList)));
  }

  return [];
}
