import {
  BooleanFilterStyleKey,
  FieldDataType,
  FilterStyleKey,
} from '@/FieldDefinitions/types';
import { TInventoryFilter } from '@/Search/SearchFilters/FilterValueSelect.types';
export class QueryFilter implements TInventoryFilter {
  field_name: TInventoryFilter['field_name'];
  field_type: TInventoryFilter['field_type'];
  data_type_name: TInventoryFilter['data_type_name'];
  field_definition_id: TInventoryFilter['field_definition_id'];
  field_values: TInventoryFilter['field_values'];
  field_style: TInventoryFilter['field_style'];
  filter_style_key: TInventoryFilter['filter_style_key'];
  field_select_value: TInventoryFilter['field_select_value'];

  constructor(props: TInventoryFilter) {
    this.data_type_name = props.data_type_name;
    this.field_type = props.field_type;
    this.field_definition_id = props.field_definition_id;
    this.field_values = props.field_values;
    this.field_style = props.field_style;
    this.field_select_value = props.field_select_value;
    this.field_name = props.field_name;

    const is_boolean = this.filter_is_boolean(props.field_values[0]);

    this.filter_style_key = is_boolean
      ? (props.field_values[0] as BooleanFilterStyleKey)
      : props.filter_style_key;
  }

  // boolean filter is kinda a black sheep - product spec doesnt act like other filters
  filter_is_boolean(value) {
    return (
      this.data_type_name === FieldDataType.Boolean &&
      ['true', 'false'].includes(value)
    );
  }

  serialize() {
    // this allows us to distinguish between custom & default field definitions
    // numerical field definitions lookup a value in the db, string means default

    const serialized_field_definition_id =
      typeof this.field_definition_id === 'number'
        ? this.field_definition_id
        : this.field_select_value;

    const filter_is_boolean = this.filter_is_boolean(this.filter_style_key);
    return {
      field_definition_id: serialized_field_definition_id,
      field_name: this.field_name,
      data_type_name: this.data_type_name,

      field_values: filter_is_boolean
        ? [this.filter_style_key]
        : this.field_values,
      filter_style_key: filter_is_boolean
        ? FilterStyleKey.HAS
        : this.filter_style_key,
    };
  }
}
